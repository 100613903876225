import './BuyContent.scss';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useCallback} from 'react';
import {useParams, useHistory} from "react-router-dom";
import FooterMeta from '../Footer/FooterMeta';
import {request} from 'graphql-request'
import {
	telegramEndpoint,
	contentQuery,
	restCall
} from '../Helper/Api';
import {getPrice, isGerman, getCurrencySign} from '../Helper/Helper';
import ReactMarkdown from 'react-markdown';
import {
	GuestDataType, IResponse,
	BuyContentType
} from '../Helper/DataTypes';
import NavigationBar from '../Components/NavigationBar';

interface ParamTypes {
	contentId: string,
}

function BuyContent(props: any) {
	const {t} = useTranslation();
	const [contentData, setContentData] = React.useState<BuyContentType | null>();
	const [processingPayment, setProcessingPayment] = React.useState<boolean>(false);
	const [isBought, setIsBought] = React.useState<boolean>(false);
	const [isAlreadyBought, setIsAlreadyBought] = React.useState<boolean>(false);
	const [boughtText, setBoughtText] = React.useState<string>(t('BoughtText1'));
	const [showBoughtText, setShowBoughtText] = React.useState<boolean>(false);
	const {contentId} = useParams<ParamTypes>();
	const history = useHistory();
	const {title, description, price, fileType, currency} = contentData || {};
	const imageTitle = fileType ? (isGerman() ? `${t(fileType)} ${t('BuyToWatch')}` : `${t('Buy')}  ${t(fileType)} ${t('ToWatch')}`) : '';
	const buttonTitle = fileType ? (isGerman() ? `${t(fileType)} ${t('Buy')}` : `${t('Buy')} ${t(fileType)}`) : '';
	let showMessage = {show: false, text: ''}
	sessionStorage.setItem('contentId', contentId);

	if (!fileType && !isAlreadyBought) {
		showMessage = {show: true, text: t('NoContentFound')};
	}

	const onBoughtContent = useCallback(() => {
		setIsBought(true);
		setTimeout(() => {
			if (!showBoughtText) {
				setBoughtText(t('BoughtText2'));
			}
		}, 5000); // show second information for user
		setTimeout(() => {
			if (!showBoughtText) {
				setBoughtText(t('BoughtText3'));
			}
		}, 10000); // show third information for user
		var boughtInterval = setInterval(function () {
			getStatus((paidContent: BuyContentType) => {
				if (paidContent.status === 'paid' || paidContent.status === 'completed') {
					setShowBoughtText(true);
					clearInterval(boughtInterval);
				}else if(paidContent.status === 'avs_required' || paidContent.status === 'avs_pending'){
					const tmpContentData : BuyContentType = {
						id: paidContent.id,
						title: paidContent.fileType ? `${t(paidContent.fileType)} ${t('From')} ${props.modelname}` : '',
						description: paidContent.fileType ? paidContent.text : '',
						price: paidContent.price,
						status: paidContent.status,
						contentId: paidContent.contentId,
						guestId: paidContent.guestId,
						messageId: paidContent.messageId,
						uhash: paidContent.uhash,
						fileType: paidContent.fileType,
						currency: paidContent.currency,
					};
					sessionStorage.setItem('contentData', JSON.stringify(tmpContentData));
					setContentData({...tmpContentData});

					clearInterval(boughtInterval);
				}
			});
		}, 1500); // check status to show user success text
	},[props.modelname, t, showBoughtText]);

	// Hook which sets the content

	useEffect(() => {
		const timeStamp: number = new Date().getTime();
		const sessionDate: number = parseInt(sessionStorage.getItem('isLoggedOutTime') || '');
		const storageContentId: string = sessionStorage.getItem('contentId') || '';
		const storageContentData: string = sessionStorage.getItem('contentData') || '';
		let getUhash = false;

		if (!sessionDate) {
			getUhash = true;
		}

		if (storageContentData && (JSON.parse(storageContentData || '{}').status === 'paid')) {
			setIsAlreadyBought(true);
		}

		// use uhash after 5 minutes of logged out
		if (timeStamp > (sessionDate + (1000 * 60 * 5))) {
			sessionStorage.removeItem('isLoggedOutTime');
			getUhash = true;
		}

		if ((!storageContentData && storageContentId) || (storageContentId !== "" && storageContentId !== JSON.parse(storageContentData).contentId) || getUhash) {
			const fetchData = async () => {
				const variables = {id: storageContentId};
				const result: any = await request(telegramEndpoint, contentQuery, variables);
				if (result.paidContent.status === 'paid') {
					setIsAlreadyBought(true);
				}

				if (!result.paidContent) {
					setContentData({
						id: '',
						title: '',
						description: '',
						price: 0,
						status: '',
						contentId: '',
						guestId: '',
						messageId: '',
						uhash: '',
						fileType: '',
						currency: '',
					});
					return;
				}
				const tmpContentData = {
					id: result.paidContent.id,
					title: result.paidContent.fileType ? `${t(result.paidContent.fileType)} ${t('From')} ${props.modelname}` : '',
					description: result.paidContent.fileType ? result.paidContent.text : '',
					price: result.paidContent.price,
					status: result.paidContent.status,
					contentId: storageContentId,
					guestId: result.paidContent.guestId,
					messageId: result.paidContent.messageId,
					uhash: getUhash ? result.paidContent.uhash : null,
					fileType: result.paidContent.fileType,
					url: result.paidContent.url,
					canceledRevoked: !result.paidContent.payable,
					currency: result.paidContent.currency,
				}
				sessionStorage.setItem('contentData', JSON.stringify(tmpContentData));
				setContentData({...tmpContentData});
			}

			if (!contentData) {
				fetchData();
			}
		} else {
			setContentData({...JSON.parse(storageContentData)});
		}

		// check payment status
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const paymentStatus = urlParams.get('history');

		if (paymentStatus === 'finish') {
			onBoughtContent();
		}

	}, []); //eslint-disable-line

	// check status from paid content to show if ready to deliver
	function getStatus(callback: any) {
		const storageContentId: string = sessionStorage.getItem('contentId') || '';
		const fetchData = async (callback: any) => {
			const variables = {id: storageContentId};
			const result: any = await request(telegramEndpoint, contentQuery, variables);
			callback(result.paidContent)
		}
		fetchData(callback);
	}

	// clickhandler for buy content
	function buyContent(useOneclick: boolean) {
		if (!props.guest) {
			history.push('/login');
		} else {
			const storageContentData: string = sessionStorage.getItem('contentData') || '';
			const data = {
				oneclick: useOneclick,
				paidContentId: JSON.parse(storageContentData).id,
				returnUrl: window.location.href
			};

			setProcessingPayment(useOneclick);

			restCall('services/telegram/initPayment', props.accessToken, data, (result: IResponse) => {
				if (result.data.checkoutUrl) {
					window.location.href = result.data.checkoutUrl;
					setProcessingPayment(false);
				} else if (result.data.success) {
					onBoughtContent();
					setProcessingPayment(false);
				}
			});
		}
	}

	function changePayment() {
		buyContent(false);
	}

	if (props.accessToken && !props.guest) {
		const loginData = {
			paidContentId: contentData ? contentData.id : '',
			uhash: contentData ? contentData.uhash : '',
		};

		restCall('login', props.accessToken, loginData, (data: any) => {
			if (data.success) {
				props.setGuest(data.data, (newGuest: GuestDataType) => {
				});
			}
		});
	}


	// container to show paid content or text if is already bought
	function getContent() {
		const avsRequired     = contentData && contentData.status === 'avs_required';
		const avsPending      = contentData && contentData.status === 'avs_pending';
		const canceledRevoked = contentData && contentData.canceledRevoked;

		let buttonContent = null;
		let imageText = null;
		if (processingPayment) {
			buttonContent = [
				<span className="spinner" key="1" />,
				<span key="2">{t('PleaseWait')}</span>
			];
		} else {
			if (avsRequired) {
				buttonContent = t('DoAvs');
				imageText = buttonContent;
			} else if (avsPending) {
				buttonContent = t('PendingAvs');
				imageText = buttonContent;
			} else {
				const currencySign = getCurrencySign(currency || '');
				buttonContent = [
					<i className={"icon icon--cart icon--white"} key="1" />,
					<span key="2">{buttonTitle + " (" + getPrice(price, currencySign) + ' ' + currencySign + ')'}</span>
				];
			}
		}

		const button =
			<button onClick={() => buyContent(true)} className={"content-button"} disabled={processingPayment || !!avsPending}>
				{buttonContent}
			</button>;


		if (!imageText) {
			imageText = isAlreadyBought ? `${fileType || ''} ${t('AlreadyBought')}` : imageTitle;
		}
		let url = contentData && contentData.url ? contentData.url : '';

		if (canceledRevoked) {
			url = isGerman() ? '/images/canceled_de.jpg' : '/images/canceled_en.jpg';
		}

		const contentImage =
			<div className={"content-image"}>
				{url && <img src={url} alt="img" className={"content-image__img"}/>}
				{!url && <div className={"icon icon--md icon--keylock icon--white"}></div>}
				{!url && <div className={"content-image__text"}>{imageText}</div>}
			</div>;

		let footerText = null;
		if (props.avs && (props.guest && !props.guest.hasAvs) && !avsPending && !avsPending) {
			footerText = <div style={{cursor: "default"}} className={"content-change-payment"}>({t('ReadyID')})</div>;
		} else if (!showMessage.show && ((props.guest && props.guest.isPayingCustomer) || isAlreadyBought) && !avsRequired && !avsPending) {
			footerText = <div onClick={isAlreadyBought ? () => {
				return null;
			} : changePayment} className={"content-change-payment"}>{isAlreadyBought ? t('BackToTelegram') : t('ChangePayment')}</div>
		}

		return (
			<div className={"content__wrapper"}>
				<div className={"content " + props.theme}>
					<NavigationBar showBack={false} />
					{contentImage}
					<div className={"content-title"}>{title}</div>
					<ReactMarkdown className={"content-description"}>{description || ''}</ReactMarkdown>
					{showMessage.show && <div className={"content__message"}>{showMessage.text}</div>}
					{!canceledRevoked && !showMessage.show && !isAlreadyBought && button}
					{!canceledRevoked && footerText}
				</div>
				<FooterMeta />
			</div>
		);
	}

	// container to show if content was bought
	const boughtContainer = (
		<div className={"content__wrapper"}>
			<div className={"content content--center " + props.theme}>
				{!showBoughtText && [
					<img className={"content__logo mb-30"} src={"/images/telegram_logo.svg"} alt={"Telegram"} key="1" />,
					<div className={"progress-bar__wrapper"} key="2">
						<div className={"progress-bar"}></div>
					</div>
				]}
				{showBoughtText &&
				<span className={"content__text"}><i className={"icon icon--mr10 icon--sm icon--check"}></i> {t('PurchaseSuccessfull')}</span>}
				{!showBoughtText && <span className={"content__subtext mb-40 mt-5"}>{boughtText}</span>}
				<div className={"content__text-wrapper " + (showBoughtText ? "content__text-wrapper--show" : "")}>
					<span className={"content__subtext mt-10"}>{t('BuySuccessText')}</span>
				</div>
			</div>
		</div>
	);

	if (isBought && (contentData && (contentData.status !== 'avs_required' && contentData.status !== 'avs_pending'))) {
		return boughtContainer;
	}

	if (!contentData) {
		return <div>Loading</div>
	} else {
		return getContent();
	}
}

export default BuyContent;
