import { useTranslation} from 'react-i18next';
import React             from 'react';
import FooterMeta        from './FooterMeta';

function Footer() {
    const {t}   = useTranslation();
    return (
        <div className={"footer"}>
            <img className={"footer__logo"} src="../images/telegram_logo.svg" alt="telegram" />
            <div className={"footer__headline"}>{t('DownloadTelegram')}</div>
            <div className={"footer__button-wrapper"}>
                <a className={"footer__button"} href={"https://play.google.com/store/apps/details?id=org.telegram.messenger&hl=de"} rel="noopener noreferrer" target="_blank" >
                    <i className={"icon icon--android"}></i>
                    <span className={"footer__button--highlight"}>Telegram</span> {t('ForAndroid')}
                </a>
                <a className={"footer__button"} href={"https://apps.apple.com/de/app/telegram-messenger/id686449807"} rel="noopener noreferrer" target="_blank" >
                    <i className={"icon icon--apple"}></i>
                    <span className={"footer__button--highlight"}>Telegram</span> {t('ForApple')}
                </a>
            </div>
            <FooterMeta />
        </div>
    )
}

export default Footer;