import './landingpage.scss';
import React        from 'react'
import Translations from '../Helper/Translation';

const Manual = () => {
    return <section className='lp-manual'>
        <div className='lp-manual__headline'>{Translations.get('lp-manual-headline')}</div>
        <div className='lp-manual__grid'>
            <div className='lp-manual__you-wrapper'>
                <div className='lp-manual__you'>
                    <div className='lp-manual__you__headline'>{Translations.get('lp-manual-you-headline')}</div>
                    <div className='lp-manual__you__text'>{Translations.get('lp-manual-you-text')}</div>
                </div>
            </div>
            <img src="../images/Message.png" alt="" className='lp-manual__paper'/>
            <div className='lp-manual__fan-wrapper'>
                <div className='lp-manual__fan'>
                    <div className='lp-manual__fan__headline'>{Translations.get('lp-manual-fan-headline')}</div>
                    <div className='lp-manual__fan__text'>{Translations.get('lp-manual-fan-text')}</div>
                </div>
            </div>
        </div>
    </section>;
}

export default Manual;