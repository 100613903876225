import React from 'react';
import { getLegalTexts} from "../Helper/GraphQLHelper";
import './legal.scss';

interface IState {
    data: any
}

interface IProps {
    product:  string,
    type:     string,
	download: boolean,
}


class LegalTexts extends React.Component<IProps,IState> {
	public static defaultProps: Partial<IProps> = {
        product:  '',
		type:     '',
		download: false,
    };

    constructor(props: any) {
		super(props);

		this.state = {
			data: null,
		};
	}

    componentDidMount(): void {
        this.getLegal();
    }

    getLegal(): void {
        getLegalTexts({type: this.props.type, product: this.props.product}, 1, 0  ).then((res: any) => {
            console.log(res);
            this.setState({data: res.data.legalTexts.items})
        });
    }

    setContainerRef(ref: any) {
        const queryString = window.location.search;
		const urlParams   = new URLSearchParams(queryString);
		const scrollTo    = urlParams.get('scrollTo');
		
		if (scrollTo) {
			setTimeout(() => {
				const elem = document.getElementById(scrollTo);
				elem && window.scrollTo({ top: elem.offsetTop});
			}, 0);
		}	
	}

	render() {
		if (!this.state.data || this.state.data.length === 0) {
			return null;
		}
		const data   = JSON.parse(JSON.stringify(this.state.data));
		const newest = data[0];

		return <div className="container-block -margin-bottom-lg terms-of-service" ref={ref => this.setContainerRef(ref)}>
                    <div className="content-box -padding-deep-help">
                        {newest && <div className='legal-texts' dangerouslySetInnerHTML={{__html: newest.html}}></div>}
						{this.props.download && <div className='legal-texts'><a className="legal-texts__download" href={"https://www.visit-x.net/rest/v1/asset/legal-text/download/" + newest.id} >Download</a></div>}

                </div>
        </div>;
	}
}

LegalTexts.defaultProps = {

};

export default LegalTexts;