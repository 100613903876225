import React, {useEffect} from 'react';
import NavigationBar      from '../Components/NavigationBar';
import LegalTexts         from './LegalTexts';

function Conditions({theme = '', showMenu = true, product = "TEL"}) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className={"terms " + theme}>
            <NavigationBar showMenu={showMenu}/>
            <LegalTexts type="GTC" product={product} download />
        </div>
    )
}

export default Conditions;