import './Login.scss';
import {useTranslation}             from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {newsletterUnsubscribe}      from '../Helper/Api';

function getEmail() {
    let email = "";
    let paramString = window.location.search;
    if (paramString) {
        let length = paramString.length;
        if(paramString.charAt(0)==='?') {
            paramString = paramString.substring(1,length);
        }
        const params = paramString.split('&') || [];

        params.map(item => {
            if (item.startsWith('email=')) {
                email = item.split("=")[1];
            }
        })
    } 

    return email;
}

function NewsletterUnsubscribe(props: any) {
    const { t } = useTranslation();
    const [success, setSuccess] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const email     = getEmail();
    const u         = urlParams.get('u') || '';

    useEffect(() => {
        if (props.accessToken && email && u) {
            newsletterUnsubscribe((data: any) => {
                if (data.success) {
                    setSuccess(true);
                }
            }, props.accessToken, email, parseInt(u));
        }
    }, [props.accessToken, email, u]);

    if (success) {
        return (
            <div className={"login-container login-container--align-center"}>
                <i className={"icon icon--lg icon--check"}></i>
                <span className={"content__text content__text--lg mt-30"}> {t('NewsletterUnsubscribed')}</span>
                <span className={"content__info content__info--sm mt-90"}>{t('WindowClose')}</span>
                <span className={"content__info content__info--sm"} dangerouslySetInnerHTML={{ __html: t('BackToTelegram') }}></span>
            </div>
        );
    } else {
        return <div>Loading...</div>
    }

}

export default NewsletterUnsubscribe;