import { useEffect } from "react";

import "vanilla-cookieconsent/src/cookieconsent.css";
import "vanilla-cookieconsent/src/cookieconsent.js";

const COOKIE_TYPE = {
    NECESSARY:  'necessary',
    FUNCTIONAL: 'functional',
    ANALYTICS:  'analytics',
}

export {
    COOKIE_TYPE
}

declare global {
    interface Window {
        initCookieConsent:any;
    }
}

export default function CookieConsent( props: any ) {
    const { lang, link, setCcCookie } = props;

    useEffect(() => {
        const cc = window.initCookieConsent();

        const splittedUrl = window.location.hostname.split('.');
        let hostname = window.location.hostname;
        if (splittedUrl.length > 2) {
            hostname = splittedUrl.slice(1).join('.');
        }

        cc.run({
            current_lang: lang,
            autorun: true,
            autoclear_cookies: true,
            page_script: true,
            showSettings: false,
            cookie_domain: hostname,

            onAccept: function (cookie: any) {
                setCcCookie(cookie);
            },

            onChange: function (cookie: any, changed_preferences: any) {
                setCcCookie(cookie);
            },

            gui_options: {
                consent_modal: {
                    layout:     'cloud',
                    position:   'bottom center',
                    transition: 'zoom'
                },
            },

            languages: {
                'de': {
                    consent_modal: {
                        title:       'Wir nutzen Cookies!',
                        description: 'Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website so angenehm wie möglich zu gestalten und um unser Produkt zu verbessern. Weitere Informationen über unsere Datenerfassungspraktiken finden Sie in unserer <a class="cc-link" href="'+link+'">Datenschutzrichtlinien</a>. <br/> <br/> Sie können entscheiden welche Cookies Sie zulassen oder ablehnen. Klicken Sie auf "Einstellungen", um eine Liste aller Kategorien angezeigt zu bekommen. Durch Klicken auf "Alle Akzeptieren" erklären Sie sich mit der Verwendung der Cookies einverstanden.',
                        primary_btn: {
                            text: 'Alle akzeptieren',
                            role: 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Einstellungen',
                            role: 'settings'
                        },
                        
                    },
                    settings_modal: {
                        title:             'Cookie Einstellungen',
                        save_settings_btn: 'Speichern',
                        accept_all_btn:    'Alle akzeptieren',
                        reject_all_btn:    'Alle ablehnen',
                        cookie_table_headers: [
                            {col1: 'Name'},
                            {col2: 'Domain'},
                            {col3: 'Expiration'},
                            {col4: 'Description'},
                            {col5: 'Type'}
                        ],
                        blocks: [
                            {
                                title:       'Cookie Nutzung',
                                description: 'Unsere Cookies fallen in folgende Kategorien'
                            }, {
                                title:       'Essentielle Cookies',
                                description: 'Diese Cookies sind notwendig für den einwandfreien technischen Betrieb der Webseite.',
                                toggle: {
                                    value:    COOKIE_TYPE.NECESSARY,
                                    enabled:  true,
                                    readonly: true
                                }
                            }, {
                                title:       'Funktionale Cookies',
                                description: 'Diese Cookies verbessern die Nutzererfahrung auf unserer Website, indem sie sich deine bevorzugten Einstellungen merken, wie z.B. die Länderauswahl.',
                                toggle: {
                                    value:   COOKIE_TYPE.FUNCTIONAL,
                                    enabled:  false,
                                    readonly: false
                                },
                            }, {
                                title:       'Analyse und Optimierung',
                                description: 'Diese Cookies werden verwendet um die Nutzung unserer Website mit zu analysieren und verschiedene Optimierungen zu testen, so dass sich unser Produkt stets weiter entwickelt.',
                                toggle: {
                                    value: COOKIE_TYPE.ANALYTICS,
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [
                                    {
                                        col1: '^_ga',
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'Dieses Cookie wird von Google Analytics genutzt um zwischen Nutzern zu unterscheiden.',
                                        col5: 'Permanent Cookie',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'google.com',
                                        col3: '1 day',
                                        col4: 'Google Analytics cookie zur Nutzerindentifikation',
                                        col5: 'Permanent Cookie'
                                    },
                                    {
                                        col1: '_gat_UA-XXXXXXXX-X',
                                        col2: 'google.com',
                                        col3: '1 minute',
                                        col4: 'Google Analytics cookie um die Anfragerate zu drosseln.',
                                        col5: 'Permanent Cookie'
                                    }
                                ]
                            },
                            {
                                title:       'Mehr Informationen',
                                description: 'Für weitere Informationen zum Thema Datenschutz lies unsere <a class="cc-link" href="'+link+'">Datenschutzerklärung</a>.'
                            }
                        ]
                    }
                },
                'en': {
                    consent_modal: {
                        title:       'We use cookies!',
                        description: 'We use cookies to make your experience on our website as pleasant as possible and to improve our product. For our data collection practices: <a class="cc-link" href="' + link + '" target="_blank">Data protection</a> <br/><br/>You can decide which cookies you allow or reject. By clicking on "Accept all" you agree to the use of cookies.',
                        primary_btn: {
                            text: 'Accept all',
                            role: 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Settings',
                            role: 'settings'
                        },
                        
                    },
                    settings_modal: {
                        title:             'Cookie settings',
                        save_settings_btn: 'Save',
                        accept_all_btn:    'Accept all',
                        reject_all_btn:    'Reject all',
                        cookie_table_headers: [
                            {col1: 'Name'},
                            {col2: 'Domain'},
                            {col3: 'Expiration'},
                            {col4: 'Description'},
                            {col5: 'Type'}
                        ],
                        blocks: [
                            {
                                title:       'Using cookies',
                                description: 'Our cookies fall into the following categories'
                            }, {
                                title:       'Necessary cookies',
                                description: 'These cookies are necessary for the website to function properly.',
                                toggle: {
                                    value:    COOKIE_TYPE.NECESSARY,
                                    enabled:  true,
                                    readonly: true
                                }
                            }, {
                                title:       'Functional cookies',
                                description: 'These cookies improve the user experience on our website by remembering your preferred settings, such as the country selection.',
                                toggle: {
                                    value:   COOKIE_TYPE.FUNCTIONAL,
                                    enabled:  false,
                                    readonly: false
                                },
                            }, {
                                title:       'Analysis and optimization',
                                description: 'These cookies are used to analyze the use of our website and to test various optimizations so that our product continues to develop.',
                                toggle: {
                                    value: COOKIE_TYPE.ANALYTICS,
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [
                                    {
                                        col1: '^_ga',
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'This cookie is used by Google Analytics to distinguish between users.',
                                        col5: 'Permanent cookie',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'google.com',
                                        col3: '1 day',
                                        col4: 'Google Analytics cookie for user identification',
                                        col5: 'Permanent cookie'
                                    },
                                    {
                                        col1: '_gat_UA-XXXXXXXX-X',
                                        col2: 'google.com',
                                        col3: '1 minute',
                                        col4: 'Google Analytics cookie to throttle the request rate.',
                                        col5: 'Permanent cookie'
                                    }
                                ]
                            },
                            {
                                title:       'More information',
                                description: 'For more information about data protction go ahead and read our <a class="cc-link" href="'+link+'">Dataprotection</a>.'
                            }
                        ]
                    }
                },
            }
        });

    }, [lang, link, setCcCookie]);

    return null;
}
