import {GuestDataType} from "./DataTypes";

function getGuest(): GuestDataType | null {
	const guest = get('guestData');

	return guest ? JSON.parse(guest) : null;
}


function get(key: string) {
	return sessionStorage.getItem(key);
}

function set(key: string, data: any) {
	if (typeof data === "object") {
		data = JSON.stringify(data);
	}

	sessionStorage.setItem(key, data);
}


export {get, set, getGuest}