const telegramEndpoint = 'https://telegram.vx-services.net/';
const vxpayEndpoint = 'https://www.visit-x.net/VXREST/v1/vxpay/';
const METHOD: string = 'POST';
const MODE: RequestMode = 'cors';

const HEADERS: any = {
	'Cache-Control': 'no-cache',
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
	'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
};

const landingPageQuery = `
query getLandingpage($slug: String!, $language: String!) {
  widget(slug: $slug) {
    logoUrl
    backgroundImageUrl
    modelname
    peerName
    phoneNumber
    theme
    actorId
    premiumBackgroundImageUrlDesktop
    premiumBackgroundImageUrlMobile
    premiumLogoUrl
    usdMode
    avs
	channelInviteUrl
    bulletTextList(language: $language) {
      bulletTexts {
        id
        masterId
        order
        language
        text
      }
    }
  }
}
`;

const contentQuery = `
query PAID_CONTENT($id: String!) {
	paidContent(id: $id) {
	  id    
	  messageId
	  price
	  status
      text
      fileType
	  guestId
      uhash
      unlockMessageSent
	  url
	  payable
	  currency
	}
  }
`;

const tipRanking = `
query TIP_RANKING($actorId: Int!, $guestId: Int!) {
	tipRanking(actorId: $actorId) {
	  topList {
		  amount
		  rank
		  rankBadge
		  isRefreshed
		  name
	  }
	  user(guestId: $guestId) {
		amount
		rank
		rankBadge
		name
		isRefreshed
	  }
	}
  }
`;

const landingPageSendMailQuery = `
mutation LANDING_PAGE_FORM($email: String!, $name: String!, $phoneNumber: String, $instagramLink: String, $messageText: String) {
  landingPage {
    sendEMail(email: $email, name: $name, phoneNumber: $phoneNumber, instagramLink: $instagramLink, messageText: $messageText)
  }
}
  `;

function checkScreenname(screenname: string, accessToken: string, callback: any) {
	fetch(`${vxpayEndpoint}signup/eval/screenname/${screenname}/?access_token=${accessToken}`)
		.then(response => response.json())
		.then(data => {
			callback(data)
		});
}

function checkEmail(email: string, accessToken: string, callback: any) {
	fetch(`${vxpayEndpoint}signup/eval/email/${email}/?access_token=${accessToken}`)
		.then(response => response.json())
		.then(data => {
			callback(data)
		});
}

function checkGuestAvs(accessToken: string, callback: any) {
	fetch(`${vxpayEndpoint}services/telegram/avs/?access_token=${accessToken}`)
		.then(response => response.json())
		.then(data => {
			callback(data)
		});
}

function restCall(route: string, accessToken: string, body: any, callback: any) {
	const requestOptions: RequestInit = {
		mode: MODE,
		method: METHOD,
		headers: HEADERS,
		body: JSON.stringify(body)
	};

	fetch(`${vxpayEndpoint}${route}?access_token=${accessToken}`, requestOptions)
		.then(response => response.json())
		.then(data => {
			callback(data)
		});
}

function restCallGet(route: string, accessToken: string,callback: any) {
	fetch(`${vxpayEndpoint}${route}?access_token=${accessToken}`)
		.then(response => response.json())
		.then(data => {
			callback(data)
		});
}

function getTokens(callback: any, actorId: number) {
	const requestOptions = {
		method: METHOD,
		headers: HEADERS,
		body: JSON.stringify({pfm: 1505, pfmsub: 'h' + actorId, ruri: window.location.href})
	};
	fetch(`${vxpayEndpoint}session/create`, requestOptions)
		.then(response => response.json())
		.then(data => {
			callback(data);
		});
}

function loadTokens(callback: any, accessToken: String, actorId: number) {
	const requestOptions = {
		method: METHOD,
		headers: HEADERS,
		body: JSON.stringify({pfm: 1505, pfmsub: 'h' + actorId})
	};
	fetch(`${vxpayEndpoint}session/load?access_token=${accessToken}`, requestOptions)
		.then(response => response.json())
		.then(data => {
			callback(data);
		})
		.catch(error => {
			callback(error)
		});
}

function verifyEmail(callback: any, accessToken: String, safeparamhash: String, u: number) {
	const requestOptions = {
		method: METHOD,
		headers: HEADERS,
	};

	const url = new URL(`${vxpayEndpoint}externredir/verifyEmail?access_token=${accessToken}`);
	const params: any = {u, safeparamhash};

	// append params
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

	fetch(url + "", requestOptions)
		.then(response => response.json())
		.then(data => {
			callback(data);
		})
		.catch(error => {
			callback(error)
		});
}

function newsletterUnsubscribe(callback: any, accessToken: String, email: string, u: number) {
	const requestOptions = {
		method: METHOD,
		headers: HEADERS,
	};

	const url = new URL(`${vxpayEndpoint}externredir/newsletterUnsubscribe?access_token=${accessToken}`);
	const params: any = {u, email};

	// append params
	Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

	fetch(url + "", requestOptions)
		.then(response => response.json())
		.then(data => {
			callback(data);
		})
		.catch(error => {
			callback(error)
		});
}

export {
	telegramEndpoint,
	landingPageQuery,
	contentQuery,
	restCall,
	restCallGet,
	checkEmail,
	checkScreenname,
	getTokens,
	loadTokens,
	verifyEmail,
	newsletterUnsubscribe,
	checkGuestAvs,
	landingPageSendMailQuery,
	tipRanking
}