import './Login.scss';
import {useTranslation}             from 'react-i18next';
import React, {useState, useEffect} from 'react';
import { verifyEmail }              from '../Helper/Api';

export function handleVerifyEmail(accessToken: string, onSuccess: () => void) {
    const urlParams     = new URLSearchParams(window.location.search);
    const safeparamhash = urlParams.get('safeparamhash') || null;
    const u             = urlParams.get('u');

    if (urlParams && safeparamhash && u) {
        verifyEmail((data: any) => {
            if (data && data.data && data.data.success) {
                onSuccess();
            }
        }, accessToken, safeparamhash, parseInt(u));
    }
}

function EmailConfirmation(props: any) {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {t}           = useTranslation();
    
    useEffect(() => {
        handleVerifyEmail(props.accessToken, () => setShowConfirmation(true));
    }, [showConfirmation, props.accessToken]);

    if (!showConfirmation) {
        return <div></div>;
    }
  
    return (
        <div className={"login-container login-container--align-center"}>
            <div className={"reset-info"}>
                    <span className={"content__text"}><i className={"icon icon--sm icon--check"}></i> {t('EmailConfirmed')}</span>
                    <div className={"content__text-wrapper content__text-wrapper--show"}>
                        <span className={"content__subtext mt-10"}>{t('WindowClose')}</span>
                        <span className={"content__subtext mt-10"} dangerouslySetInnerHTML={{__html: t('BackToTelegram')}}></span>
                    </div>
                </div>     
        </div>
    );
}

export default EmailConfirmation;