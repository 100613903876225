import React, {useEffect} from 'react';
import Footer from '../Footer/Footer';
import {Header, getAboTile, createGuestAbos} from "../Helper/PremiumHelper";
import {getGuest} from "../Helper/SessionHelper";
import {GuestAbo} from "../Helper/DataTypes";
import {queryAvailableAbosForGuest} from '../Helper/GraphQLHelper';
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import NavigationBar from '../Components/NavigationBar';
import {getBackground, onWindowResize} from "./PremiumHelper";
import {restCall} from "../Helper/Api";

function queryAbos(actorId: number, setter: Function) {
	const guest = getGuest();
	if (guest) {
		queryAvailableAbosForGuest(guest, null, true, 'Telegram').then(
			(res: any) => {
				if (res.data && res.data.guest && res.data.guest.availableAbos) {
					const abos = res.data.guest.availableAbos;
					createGuestAbos(abos, setter);
				}
			}
		);
	}
}

function UserAbos(props: any) {
	const data = {...props};
	const [abos, setAbos] = React.useState<Array<GuestAbo> | null>();
	const {t} = useTranslation();
	const history = useHistory();
	const [modalAboId, setModalAboId] = React.useState(0);
	const [backgroundImageUrl, setBackgroundImageUrl] = React.useState(getBackground(data));
	const [showUnsubscribeConfirmationModal, setShowUnsubscribeConfirmationModal] = React.useState(false);
	const guest = getGuest();
	let Abos;

	if (!guest) {
		history.push('/vip/');
	}

	useEffect(() => {
		if (!abos) {
			queryAbos(0, setAbos);
		}
	}, [abos]);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	onWindowResize(() => {
			const tmp = getBackground(data);
			if (tmp !== backgroundImageUrl) {
				setBackgroundImageUrl(tmp);
			}
		}
	);

	const updatePremiumStatus = () => {
		restCall('services/telegram/premiumStatus', data.accessToken, {actorId: data.actorId}, (result: any) => {
			if (result && result.success && result.data && result.data.premiumStatus) {
				props.setPremiumStatus(result.data.premiumStatus);
			}
		});
	};

	const style = {backgroundImage: `url(${backgroundImageUrl})`};

	let shownAbos = [];
	if (abos && abos.length > 0) {
		for (let i = 0; i < abos.length; i++) {
			const currentInstance = abos[i].instances[abos[i].instances.length - 1];
			if (currentInstance && currentInstance.status !== 'STOPPED') {
				shownAbos.push(abos[i]);
			}
		}
	}
	if (shownAbos.length < 1) {
		Abos = (
			<div className={"abo-tile__no-abo"}>
				<div className={"abo-tile__no-abo__headline"}>{t('NoAbos')}</div>
				<div className={"abo-tile__no-abo__subline pointer"} onClick={() => (history.push('/vip/'))}>
					{t('ShowAllSubs')}
					<i className="icon icon--arrow-right icon--sm-right icon--blue"></i>
				</div>
			</div>
		);
	} else {
		Abos = (
			<div className={"abo-tile__container abo-tile__container--multiple content__wrapper--premium "}>
				{shownAbos.map((abo: GuestAbo, i: number) => getAboTile(abo, queryAbos, data, setAbos, history, t, modalAboId, setModalAboId, updatePremiumStatus, setShowUnsubscribeConfirmationModal, showUnsubscribeConfirmationModal))}
			</div>
		);
	}

	return (
		<div className={"premium premium--user " + (data.theme)} style={style}>
			<NavigationBar showMenu={true} />
			<div className={"premium__wrapper"}>
				<Header logo={data.premiumLogoUrl} isMySubs={true} t={t} />
				{Abos}
				<div className='abo-tile__container abo-tile__button-info'>* {t('inc-vat')}</div>
				{shownAbos.length > 0 && <Footer {...data} />}
			</div>
		</div>);
}

export default UserAbos;