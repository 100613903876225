import './tip.scss';
import React        from 'react'
import AddTip       from './AddTip';
import Ranking      from './Ranking';
import Benefit      from '../images/benefit.gif';
import FooterMeta   from '../Footer/FooterMeta';
import Translations from '../Helper/Translation';
import {restCall}   from '../Helper/Api';
import {request}    from 'graphql-request'
import {
	telegramEndpoint,
	tipRanking
} from '../Helper/Api';

let tryCounter = 0;

const HISTORY_PARAM = (new URL(window.location.href)).searchParams.get('history');
const AMOUNT_PARAM  = (new URL(window.location.href)).searchParams.get('amount');

interface IProps {
    backgroundImageUrl: string,
    modelname: string,
    accessToken: string,
    guest: any,
    setGuest: any,
    actorId: number,
    match: any,
    usdMode: boolean,
    avs: boolean,
    peerName: string,
    logoUrl: string,
}

interface IState {
    hasTipped:  boolean;
    rank:       number,
    tipHash:    string,
    tipList:    any,
    tipUser:    any,
}

export default class Tip extends React.Component<IProps, IState> {    
    constructor(props: IProps) {
        super(props);

        this.state = {
            hasTipped: HISTORY_PARAM === 'finish' && this.props.guest && this.props.guest.id > 0,
            tipList:   null,
            tipUser:    null,
            rank: 1,
            tipHash: '',
        }
    }

    componentDidMount() {
        if (HISTORY_PARAM === 'close') {
            this.removeParam('history');
        }   
        if (AMOUNT_PARAM && !this.state.hasTipped) {
            this.removeParam('amount');
        }   

        this.getTipRankingData();
    }

    shouldComponentUpdate(nextProps: IProps, nextState: IState) {
        if (this.props !== nextProps) {
            if ((this.props.accessToken !== "") && !nextProps.guest) {
                const id = this.props.match.params.id;
                if (id && !nextProps.guest) {
                    this.getUHash(id);
                }
            }
        }
        return (nextProps !== this.props) || (nextState !== this.state) || (this.props.guest !== nextProps.guest) ;
    }

    login(uhash: string = '', tipHash: string = '') {
        const loginData = {
            uhash,
        };

        restCall('login', this.props.accessToken, loginData, (data: any) => {
            if (data.success) {
                this.props.setGuest(data.data, () => {
                    this.setState({tipHash})
                });
            }
        });
    }

    getUHash(id: string) {
        const data = {
            tipHash: id
        }
        restCall('services/telegram/tip/verify', this.props.accessToken, data, (result: any) => {
            if (result.success) {         
                this.login(result.data.uhash, id);       
            } else if (result.error.code === 1) {
            }
        });
    }

    removeParam(param: string) {
        const urlParams = new URL(window.location.href);
        urlParams.searchParams.delete(param);
        window.history.replaceState({}, '', urlParams.toString());
    }

    getBenefitsInfo() {
        if (this.state.hasTipped) {
            const onClick = () => {
                this.removeParam('history');
                this.removeParam('amount');
                this.setState({hasTipped: false});
            }
            return (
            <div className="benefits__container">
                <div className="benefits__headline">{Translations.get('tip-benefit-tipped-headline')}</div>
                <div className="benefits__text">{Translations.get('tip-benefit-tipped-text-1')}<br /> {Translations.get('tip-benefit-tipped-text-2')}</div>
                <div className="benefits__button" onClick={onClick} >{Translations.get('tip-benefit-tipped-button')} </div>
            </div>
            );
        }

        return (
            <div className="benefits__container">
                <div className="benefits__headline">{Translations.get('tip-benefit-headline')}</div>
                <img className="benefits__image" src={Benefit} alt="" />
                <div className="benefits__text benefits__text--transform">{Translations.get('tip-benefit-subline', {modelname: this.props.modelname})}</div>
            </div>
        );
    }

    getCurrency() {
        return this.props.usdMode ? '$' : '€';
    }

    getTipRankingData() {
        const variables = {
            actorId: this.props.actorId,
            guestId: this.props.guest ?  this.props.guest.id : 0,
        }
        request(telegramEndpoint, tipRanking, variables).then((response: any) => {
            if (!response.tipRanking.user || !this.state.hasTipped) {
                this.setState({tipList: response.tipRanking.topList});
            } else if (tryCounter < 5 && (this.state.hasTipped && (response.tipRanking && response.tipRanking.topList && (response.tipRanking.user && !response.tipRanking.user.isRefreshed)))) {
                this.setState({tipList: response.tipRanking.topList}, () => {
                    setTimeout(() => {
                        this.getTipRankingData();
                        tryCounter++;
                    }, 1000);
                });
            } else {
                tryCounter = 0;
                this.removeParam('history');
                this.removeParam('amount');
                this.setState({tipList: response.tipRanking.topList, tipUser: response.tipRanking.user});
            }
        });
    }

    getInfo() {
        if (!this.state.tipUser) {
            return [
            <div className={"ranking ranking--succes"} key="1">
                <span className="spinner spinner--black"></span>
                <div className="ranking__update">{Translations.get('tip-ranking-load')}</div>
            </div>,
            this.state.rank > 19 && <div className="ranking__info ranking__info--center" key="2">{Translations.get('tip-ranking-tipped-text-1')} <br /> {Translations.get('tip-ranking-tipped-text-2', {modelname: this.props.modelname})}</div>
        ]; 
        }
        const rank = this.state.tipUser.rank
        const isPowerfan = rank < 4;
        const amount = () => {
            const amount = AMOUNT_PARAM || this.state.tipUser.amount.toString();
            if (amount.indexOf('.') === -1) {
                return amount + ',00';
            } else if (amount.split('.')[1].length === 1) {
                return amount.replace('.',',') + '0';
            }
            return amount.replace('.',',');
        }

        return [
            <div className={"ranking ranking--succes"} key="1">
                <span className="ranking__icon" role="img" aria-label="ranking">🏆 </span>
                <div className="ranking__headline">{Translations.get('tip-ranking-headline', {place: rank})}</div>
                {isPowerfan && <div className="ranking__update">{Translations.get('tip-ranking-text-1')}<br /> {Translations.get('tip-ranking-text-2')}</div>}
                {isPowerfan && <div className="ranking__update-badge"><span role="img" aria-label="money">💸</span> {this.state.tipUser.rankBadge} </div>}
                <div className="ranking__info">{Translations.get('tip-ranking-info',  {modelname: this.props.modelname, amount: amount(), currency: this.getCurrency()})}</div>
            </div>,
            this.state.rank > 19 && <div className="ranking__info ranking__info--center" key="2">{Translations.get('tip-ranking-tipped-text-1')} <br /> {Translations.get('tip-ranking-tipped-text-2', {modelname: this.props.modelname})}</div>
        ]; 
    }

    render() {      
        return (
            <div className={"tipping" + (this.state.hasTipped ? " tipping--no-padding" : "")}>
                <div className="tipping__image" style={{backgroundImage: `url(${this.props.backgroundImageUrl})`}}> 
                <img className="tipping__logo" src={this.props.logoUrl} alt="" />
                    {this.state.hasTipped ? this.getInfo() : <AddTip accessToken={this.props.accessToken} guest={this.props.guest} setGuest={this.props.setGuest} actorId={this.props.actorId} modelName={this.props.modelname} usdMode={this.props.usdMode}  match={this.props.match} peerName={this.props.peerName} removeParam={this.removeParam} avs={this.props.avs} />}
                </div>
                {this.getBenefitsInfo()}
                {this.state.tipList && <Ranking rankingList={this.state.tipList} modelName={this.props.modelname} />}
                <div className="footer-meta__wrapper">
                    <FooterMeta />
                </div>
            </div>
        )
    }
}
