import './landingpage.scss';
import React        from 'react'
import Translations from '../Helper/Translation';
import {isGerman}   from '../Helper/Helper';

class Features extends React.Component {

    getList() {
        const list = [
            {
                headline: Translations.get("lp-features-list-1-headline"),
                text:     Translations.get("lp-features-list-1-text")
            },
            {
                headline: Translations.get("lp-features-list-2-headline"),
                text:     Translations.get("lp-features-list-2-text")
            },
            {
                headline: Translations.get("lp-features-list-3-headline"),
                text:     Translations.get("lp-features-list-3-text")
            }
        ];

        return <ul className='lp-features__list'>
            {list.map((item,index) => (
                <li className='lp-features__list-item' key={index}>
                        <div className='lp-features__list-item__headline'><span>+</span>{item.headline}</div>
                        <div className='lp-features__list-item__text'>{item.text}</div>
                </li>
            ))}
        </ul>
        
    }
    render() {
        return <section className='lp-features'>
            <div className='lp-feature__headline-wrapper'>
                <div className='lp-features__headline lp-features__headline--blue'>additional</div>
                <div className='lp-features__headline'>features</div>
            </div>
            {isGerman() ? <img src="../images/featuresDE.png" alt="" className='lp-features__image'/> : <img src="../images/featuresEN.png" alt="" className='lp-features__image'/>}
            {this.getList()}

        </section>;
    }
}

export default Features;