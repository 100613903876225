import './Sidebar.scss';
import React, {useState} from 'react';
import {useHistory}      from "react-router-dom";
import {useTranslation}  from 'react-i18next';

function isActiveRoute(link: string) {
    return link === window.location.pathname;
}

function createLink(name: string, link: string, iconClass?: string) {
    return {
        name,
        link,
        isActive: isActiveRoute(link),
        icon: iconClass ? 'icon ' + iconClass : '',
    }
}

type TProps = {
    isNavigationBar: boolean;
}

const Sidebar: React.FC<TProps> = ({isNavigationBar = false}) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const history    = useHistory();
    const {t}        = useTranslation();
    const links      = [];
    const guestData  = JSON.parse(sessionStorage.getItem('guestData') || '{}');
    const screenname = guestData.screenname;

    if (screenname) {
        links.push(createLink(t('MyAbos'), '/subscriptions/', 'icon--send'));
        links.push(createLink('VIP', '/vip/', 'icon--star'));
    } else {
        links.push(createLink(t('Login'), '/login/', 'icon--login'));
        links.push(createLink(t('Signup'), '/signup/', 'icon--signup'));
    }

    // clickhandler for sidebar item
    function onClick(link: string) {
        if (isActiveRoute(link)) {
            return null;
        }
        if (link === '/logout/') {
            sessionStorage.clear();
            localStorage.clear();
            const timeStamp = new Date().getTime();
            sessionStorage.setItem('isLoggedOutTime', timeStamp.toString());
            history.go(0);
        } else {
            history.push(link);
        }
    }

    return (
        <div className={"sidebar "  + (isNavigationBar ? "sidebar-relative" : "")}>
            <div className={"sidebar-button "  + (isNavigationBar ? "sidebar-button--navigation-bar" : "")} onClick={() => setShowSidebar(!showSidebar)} >
                <i className={"icon icon--sm icon--mt-0 icon--menu "}></i>
            </div>
            <div className={"sidebar-container" + (showSidebar ? " sidebar-container--show ":"" )}>
                <div className={"sidebar-button"} onClick={() => setShowSidebar(!showSidebar)} >
                    <i className={"icon icon--sm icon--mt-0 icon--close "}></i>
                </div>


                <div className={"sidebar-list"}>
                    { screenname && <div className="sidebar-list__item sidebar-list__item--name sidebar-list__item--no-cursor"><i className={'icon icon--xs icon--user icon--user--grey'}></i>{screenname} </div>  }
                    {links.map((link,index) => (

                        <div key={index} className={"sidebar-list__item" + (isNavigationBar ? "" : " sidebar-list__item--theme") + (link.isActive ? " sidebar-list__item--active" : "")} onClick={() => onClick(link.link)}>
                            {link.icon && <i className={link.icon }></i>}
                            {link.name}
                            </div>)
                    )}
                    { screenname && <div onClick={()=>onClick('/logout/')}className="sidebar-list__item sidebar-list__item--bottom"><i className={'icon icon--logout'}></i> {t('Logout')} </div>  }
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
