import React, {Fragment, useEffect, useCallback, useState} from 'react';
import {Helmet} from 'react-helmet';
import {request} from 'graphql-request'
import {useTranslation} from 'react-i18next';
import {isGerman, getLanguage} from './Helper/Helper';
import './Css/App.css';
import Premium from './Premium/Premium';
import UserAbos from './Premium/UserAbos';
import SignUp from './Login/SignUp';
import Login from './Login/Login';
import Logout from './Login/Logout';
import EmailConfirmation from './Login/EmailConfirmation';
import ResetPassword from './Login/ResetPassword';
import BuyContent from './BuyContent/BuyContent';
import Footer from './Footer/Footer';
import {telegramEndpoint, landingPageQuery, getTokens, loadTokens, restCall} from './Helper/Api';
import {
	BrowserRouter as Router,
	Route, Switch
} from 'react-router-dom';
import {CaptchaDataType, GuestDataType, IResponse} from './Helper/DataTypes';
import Conditions from './Terms/Conditions';
import Imprint from './Terms/Imprint';
import DataProtection from './Terms/DataProtection';
import NewsletterUnsubscribe from './Login/NewsletterUnsubscribe';
import JumpStart from './Login/JumpStart';
import RouterHelper from './Helper/RouterHelper';
import {getGuest} from "./Helper/SessionHelper";
import i18n from '../src/Helper/i18n/index';
import {initVXPay} from "./Helper/VXPayHelper";
import Landingpage from "./Landingpage/Landingpage";
import Translations from './Helper/Translation';
import Tip from './Tip/Tipping';
import Cookie, { COOKIE_TYPE } from './Cookie/Cookie';
import Cookies from 'js-cookie';
import {setAnalyticsCookies} from './Cookie/CookieHelper';


type ExpectationsType = { [index: string]: Array<string> }
type AboTileType = { pictureUrl: string, name: string, description: string, text: string, price: string, paymentIntervall: string, link: string, discountDate: string, discountText: string, discountButton: string }
type AbosType = { [index: string]: Array<AboTileType> }
type GuestClbFuncType = (newGuest: GuestDataType) => any;

export interface IPageData {
	readonly actorId: number,
	readonly logoUrl: string,
	readonly backgroundImageUrl: string,
	readonly premiumBackgroundImageUrlDesktop: string | null,
	readonly premiumBackgroundImageUrlMobile: string | null,
	readonly modelname: string,
	readonly peerName: string,
	readonly phoneNumber: string,
	readonly usdMode: boolean,
	readonly avs: boolean,
	readonly channelInviteUrl: string | null,
	theme: string,
	backupExpectations: ExpectationsType,
	abos: AbosType,
}


function App() {
	const {t} = useTranslation();
	new Translations(t);
	const cc = Cookies.get('cc_cookie') ? JSON.parse(Cookies.get('cc_cookie')) : {level: [COOKIE_TYPE.NECESSARY]} ;
	const [loading, setLoading] = React.useState<boolean>(true);
	const [agbChecked, setAgbChecked] = React.useState<boolean>(true);
	const [pageData, setPageData] = React.useState<IPageData | null>();
	const [accessToken, setAccessToken] = React.useState<string>('');
	const [guestData, setGuestData] = React.useState<GuestDataType>();
	const [captchaData, setCapchaData] = React.useState<CaptchaDataType>();
	const [avsCheckoutUrl, setAvsCheckoutUrl] = React.useState<string | null>(null);
	const [premiumStatus, setPremiumStatus] = React.useState<string | null>(null);
	const [ccCookie, setCcCookie] = useState(cc);

	const useAnalyticsCookies  = ccCookie && ccCookie.level.includes(COOKIE_TYPE.ANALYTICS);
	setAnalyticsCookies(useAnalyticsCookies);
	const urlParams = new URLSearchParams(window.location.search);

	let host = window.location.host;
	if (host.indexOf('localhost:') > -1 || host.indexOf('dev4.x:') > -1) {
		host = 'admin.messenger.vip';
		// host = 'jayjaypink.messenger.vip'; // lexyroxx.messenger.vip
	}

	const safeparamhash = urlParams.get('safeparamhash') || null;
	if (safeparamhash && !window.location.pathname.includes('emailconfirmation') && !window.location.pathname.includes('/reset') && !window.location.pathname.includes('/login')) {
		window.location.pathname = '/emailconfirmation/';
	}

	const [subdomain, ,] = host.split('.');

	if (pageData) {
		initVXPay(pageData.actorId, isGerman() ? 'de' : 'en');
	}

	const changeLanguage = (language: string = window.navigator.language.split('-')[0]) => {
		const lng = language === 'de' ? 'de' : 'en';
		i18n.changeLanguage(lng);
		Translations.set(t);
	}

	const changeLanguageCallback = useCallback(
		changeLanguage,
		[],
	)

	useEffect(() => {
		changeLanguageCallback();
	}, [changeLanguageCallback]);

	useEffect(() => {
		const path = window.location.pathname;
		if (path.slice(-1) !== '/') {
			window.location.pathname = window.location.pathname + '/';
		}
	}, []);
	useEffect(() => {
		const fetchData = async () => {
			const variables = {
				slug: subdomain,
				language: isGerman() ? 'DE' : 'EN',
			};
			const result: any = await request(telegramEndpoint, landingPageQuery, variables);
			if (result) {
				if (result.widget) {
					result.widget = {...result.widget};
					setPageData(result.widget);
				}
				setLoading(false);
			}
		};

		if (!pageData) {
			fetchData();
		}
	}, []); //eslint-disable-line

	// Get Access Token and Transfer Token
	useEffect(() => {
		if (!pageData) {
			return;
		}
		initTokens();
	}, [pageData]); //eslint-disable-line

	function initTokens() {
		let storageAccessToken: string = sessionStorage.getItem('accessToken') || '';
		let storageTransferToken: string = sessionStorage.getItem('transferToken') || '';
		if (!storageAccessToken && !storageTransferToken) {
			getNewTokens();
		} else {
			reloadToken(storageAccessToken);
		}
	}

	function getNewTokens() {
		if (!pageData) {
			return;
		}

		getTokens((data: any) => {
			if (data.success) {
				sessionStorage.setItem('accessToken', data.data.access_token);
				sessionStorage.setItem('transferToken', data.data.transfer_token);
				setAccessToken(data.data.access_token);
				setCapchaData(data.data.captcha);
				loginUser(data.data.access_token);
			}
		}, pageData.actorId);
	}

	function reloadToken(accessToken: String) {
		if (!pageData) {
			return;
		}

		loadTokens((data: any) => {
			if (!data.success) {
				getNewTokens();
			} else {
				sessionStorage.setItem('accessToken', data.data.access_token);
				sessionStorage.setItem('transferToken', data.data.transfer_token);
				setAccessToken(data.data.access_token);
				setCapchaData(data.data.captcha);
				loginUser(data.data.access_token);
			}
		}, accessToken, pageData.actorId);
	}

	function loginUser(accessToken: string) {
		const uhash = localStorage.getItem('uhash');

		if (uhash && accessToken) {
			const loginData = {
				uhash,
			}
			restCall('login', accessToken, loginData, (data: any) => {
				if (data.success) {
					setGuest(data.data, () => {});
				} else {
					localStorage.clear();
       				sessionStorage.clear();
				}
			});
		}
	}

	function prepareAnonymousTokens(accessToken: String) {
		if (!pageData) {
			return;
		}

		loadTokens((data: any) => {
			if (data.success && data.data.id) {
				getNewTokens();
			}
		}, accessToken, pageData.actorId);
	}

	function setGuest(newGuest: GuestDataType, callback: GuestClbFuncType) {
		sessionStorage.setItem('guestData', JSON.stringify(newGuest));
		localStorage.setItem('uhash', newGuest.uhash);
		callback && callback(newGuest);
		setGuestData(newGuest || undefined);
	}

	const guest = getGuest();
	if (guest && !guestData) {
		setGuestData(guest);
	}

	if (guest && premiumStatus === 'avs_required' && accessToken && avsCheckoutUrl === null) {
		setAvsCheckoutUrl('loading');
		restCall('services/telegram/avsCheckoutUrl', accessToken, {returnUrl: window.location.origin + window.location.pathname}, (result: IResponse) => {
			if (result && result.success && result.data && result.data.checkoutUrl) {
				setAvsCheckoutUrl(result.data.checkoutUrl);
			}
		});
	}

	if (guest && (['avs_required', 'avs_pending', 'inactive'].indexOf(guest.premiumStatus) > -1) && premiumStatus === null && accessToken && pageData) {
		setPremiumStatus('loading');
		restCall('services/telegram/premiumStatus', accessToken, {actorId: pageData.actorId}, (result: any) => {
			if (result && result.success && result.data && result.data.premiumStatus) {
				setPremiumStatus(result.data.premiumStatus);
			}
		});
	}

	let content;
	if (loading) {
		return null;
	}

	if (pageData) {
			const themeParam = urlParams.get('theme') ? urlParams.get('theme') : '';
		if (themeParam === 'light') {
			pageData.theme = themeParam;
			document.body.classList.remove('c_darkmode');
		} else if (themeParam === 'dark') {
			pageData.theme = themeParam;
			document.body.classList.add('c_darkmode');
		}

		const bgStyles = {
			'backgroundImage': `url(${pageData.backgroundImageUrl})`
		};
		const addContactUrl = `https://t.me/${pageData.peerName}`;
		content = <Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t('metaTitle', {modelname: pageData.modelname})}</title>
				<meta name="description" content={t('metaDescription', {modelname: pageData.modelname})} />
				<link rel="canonical" href={`https://${subdomain}.messenger.vip`} />
				<meta name="robots" content="index, follow" />
			</Helmet>
			<div className={`bg ${pageData.theme}`} style={bgStyles} />
			<div className={`page ${pageData.theme}`}>
				<div className="logo">
					<img alt="" src={pageData.logoUrl} />
				</div>

				<div className="telegram-widget-container">
					<div className="telegram-widget-wrapper grid-100 tablet-grid-100 mobile-grid-100">
						<header>
							<div className="telegram-widget-headtitle">
								<img className="logowidth" src="images/telegram_logo.svg" alt="" width="" height="" />
								<span>{pageData.channelInviteUrl ? t('JoinChannelHeadline') : t('AddContact')}</span>
							</div>
						</header>
						

						<div className="telegram-widget-content">
							{!pageData.channelInviteUrl && <ol>
								<li><span className={"telegram-widget-content-li-wrapper"}>{t('Text1', {number: pageData.phoneNumber})}</span></li>
								<li><span className={"telegram-widget-content-li-wrapper"}>{t('Text2')}</span></li>
								<li><span className={"telegram-widget-content-li-wrapper"}>{t('Text3')}</span></li>
							</ol>}
							{!pageData.channelInviteUrl && <p className="telegram-widget-info">
								{pageData.phoneNumber}
							</p>}
							{pageData.channelInviteUrl && <div>
								<p className="join-channel-text">{t('JoinChannelText-1')}</p>
								<p className="join-channel-text">{t('JoinChannelText-2')}</p>
							</div>}
							<div className="telegram-widget-disclaimer">
								<input type="checkbox"
								       name="agb"
								       id="agb-checkbox"
								       checked={agbChecked}
								       onChange={e => setAgbChecked(e.target.checked)} />
								<span>{t('TextAgb1')}</span>
								<a href={"/conditions/"} rel="noopener noreferrer" target="_blank">{t('AbgLink')}</a>
								<span>{t('TextAgb2')}</span>
								<a href={"/datenschutz/"} rel="noopener noreferrer" target="_blank">{t('DataProtectionLink')}</a>.
							</div>
							<div className="telegram-widget-ctabtn">
								<a className="telegram-widget-ctabtn-blue" href={pageData.channelInviteUrl ? pageData.channelInviteUrl : addContactUrl} rel="noopener noreferrer" target="_blank">
									{pageData.channelInviteUrl ? t('JoinChannelButton'): t('ButtonContact', {number: pageData.phoneNumber})}
								</a>
							</div>
						</div>
					</div>
				</div>
				{/*<div className={"premium-ad"}>*/}
				{/*	<div className={"premium-ad__headline"}>join My <span className={"premium-ad__headline--bold"}>telegram premium</span></div>*/}
				{/*	<div className={"premium-ad__text"}>{pageData.expectations[language][0]}</div>*/}
				{/*	<Link to="/premium" className={"premium-ad__button"}>Zum Telegram Premium <i className={"premium-ad__button-icon"}></i></Link>*/}
				{/*	<div className={"premium-ad__abo-container"}>*/}
				{/*		*/}
				{/*		{ pageData.abos[language].slice(0, 3).map((abo,i) => (*/}
				{/*			<div className={"premium-ad__abo-tile " + (i === 0 ? "premium-ad__abo-tile--first" : "")} key={i}>*/}
				{/*			<div className={"premium-ad__abo-tile__image-container"}>*/}
				{/*				<img className={"premium-ad__abo-tile__image"} src={abo.imgUrl} alt={"abo"} />*/}
				{/*				{ abo.discountButton && <div className={"premium-ad__abo-tile__badge"} style={{backgroundImage: `url(${abo.discountButton})`}}></div>}*/}
				{/*			</div>*/}
				{/*			<div className={"premium-ad__abo-tile__headline"}>{abo.headline}</div>*/}
				{/*			<div className={"premium-ad__abo-tile__text"}>{abo.discountText}</div>*/}
				{/*			</div>))*/}
				{/*		}		*/}

				{/*	</div>*/}
				{/*</div>*/}
				<Footer />
			</div>
		</Fragment>;
	} else {
		content = <Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t('lp-title')}</title>
				<meta name="description" content={t('lp-title')} />
				<link rel="canonical" href={`https://messenger.vip`} />
				<meta name="robots" content="index, follow" />
			</Helmet>
			<Landingpage changeLanguage={changeLanguage} />
		</Fragment>
	}

	if (!pageData) {
		return (
			<Router>
				<Switch>
					<Route path={[RouterHelper.Routes.DE.CONDITIONS, RouterHelper.Routes.EN.CONDITIONS, RouterHelper.Routes.DE.AGB, RouterHelper.Routes.EN.AGB]}>
						<Conditions theme={'light'} showMenu={false} product="TGLP" />
					</Route>
					<Route path={[RouterHelper.Routes.DE.DATA_PROTECTION + ':version', RouterHelper.Routes.EN.DATA_PROTECTION + ':version', RouterHelper.Routes.DE.DATA_PROTECTION, RouterHelper.Routes.EN.DATA_PROTECTION]}
					       render={(props) =>
						       <DataProtection theme={'light'} showMenu={false} {...props} product="TGLP" />
					       }
					/>
					<Route path={[RouterHelper.Routes.DE.IMPRINT, RouterHelper.Routes.EN.IMPRINT]}>
						<Imprint theme={'light'} showMenu={false} product="TGLP" />
					</Route>
					<Route path={RouterHelper.Routes.ANY.DEFAULT}>
						{content}
						<Cookie lang={getLanguage()} link={RouterHelper.Routes.DE.DATA_PROTECTION} setCcCookie={setCcCookie}/>
					</Route>
				</Switch>
			</Router>
		);
	}

	return (
		<Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t('metaTitle', {modelname: pageData.modelname})}</title>
				<meta name="description" content={t('metaDescription', {modelname: pageData.modelname})} />
				<link rel="canonical" href={`https://${subdomain}.messenger.vip/${window.location.pathname}`} />
				<meta name="robots" content="index, follow" />
			</Helmet>
			<div className={"site__wrapper " + pageData.theme}>
				<Router>
					<Switch>
						<Route path={[RouterHelper.Routes.DE.BUY_CONTENT, RouterHelper.Routes.EN.BUY_CONTENT]}>
							<BuyContent {...pageData} guest={guestData} accessToken={accessToken} setGuest={setGuest} />
						</Route>
						<Route path={RouterHelper.Routes.ANY.SIGNUP}>
							<SignUp {...pageData} accessToken={accessToken} setGuest={setGuest} prepareAnonymousTokens={prepareAnonymousTokens} captchaData={captchaData} />
						</Route>
						<Route path={RouterHelper.Routes.ANY.LOGIN}>
							<Login {...pageData} accessToken={accessToken} setGuest={setGuest} guest={guest} prepareAnonymousTokens={prepareAnonymousTokens} captchaData={captchaData} getNewTokens={getNewTokens}/>
						</Route>
						<Route path={RouterHelper.Routes.ANY.LOGOUT}>
							<Logout />
						</Route>
						<Route path={RouterHelper.Routes.ANY.RESET}>
							<ResetPassword {...pageData} accessToken={accessToken} />
						</Route>
						<Route path={RouterHelper.Routes.ANY.EMAIL_CONFIRMATION}>
							<EmailConfirmation {...pageData} accessToken={accessToken} />
						</Route>
						<Route path={RouterHelper.Routes.ANY.NEWSLETTER_UNSUBSCRIBE}>
							<NewsletterUnsubscribe {...pageData} accessToken={accessToken} />
						</Route>
						<Route path={RouterHelper.Routes.ANY.JUMP_START}>
							<JumpStart {...pageData} />
						</Route>
						<Route path={RouterHelper.Routes.ANY.VIP}>
							<Premium {...pageData} avsCheckoutUrl={avsCheckoutUrl}
									premiumStatus={premiumStatus}
									accessToken={accessToken}
									setPremiumStatus={setPremiumStatus} />
						</Route>
						<Route path={[RouterHelper.Routes.ANY.TIP + ":id", RouterHelper.Routes.ANY.TIP]} render={(props) =>
							<Tip {...props} {...pageData} guest={guestData} accessToken={accessToken} setGuest={setGuest} />
						}>
						</Route>
						<Route path={RouterHelper.Routes.ANY.ABOS}>
							<UserAbos {...pageData}
							          accessToken={accessToken}
							/>
						</Route>
						<Route path={[RouterHelper.Routes.DE.CONDITIONS, RouterHelper.Routes.EN.CONDITIONS, RouterHelper.Routes.DE.AGB, RouterHelper.Routes.EN.AGB]}>
							<Conditions theme={pageData.theme} product="TEL" />
						</Route>
						<Route path={[RouterHelper.Routes.DE.DATA_PROTECTION + ':version', RouterHelper.Routes.EN.DATA_PROTECTION + ':version', RouterHelper.Routes.DE.DATA_PROTECTION, RouterHelper.Routes.EN.DATA_PROTECTION]}
							render={(props) =>
								<DataProtection theme={pageData.theme} {...props} product="TEL" />
							}
						/>
						<Route path={[RouterHelper.Routes.DE.IMPRINT, RouterHelper.Routes.EN.IMPRINT]}>
							<Imprint theme={pageData.theme} product="TEL" />
						</Route>
						<Route path={[RouterHelper.Routes.DE.IMPRINT, RouterHelper.Routes.EN.IMPRINT]}>
							<Imprint theme={pageData.theme} product="TEL" />
						</Route>
						<Route path={RouterHelper.Routes.ANY.DEFAULT}>
							{content}
						</Route>
					</Switch>
				</Router>
				<Cookie lang={getLanguage()} link={RouterHelper.Routes.DE.DATA_PROTECTION} setCcCookie={setCcCookie}/>
			</div>
		</Fragment>
	);
}

export default App;
