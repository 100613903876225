import {useHistory}     from "react-router-dom";
import React            from 'react';
import Sidebar          from './Sidebar';
import {useTranslation} from 'react-i18next';
import PropTypes         from 'prop-types';

function NavigationBar(props: any) {
    const history = useHistory();    
    function goBack() {
        history.goBack();
    }

    const {t}      = useTranslation();
    const showBack = props.showBack && history.length > 1;
    return (
        <div className={"navigation-bar" + (!showBack ? " navigation-bar--right" : "") + (props.usePadding ? " content__wrapper--premium" : "")}>
           {showBack &&  <div onClick={() => goBack()} className={"navigation-bar__back-btn"}>
                <i className={"icon icon--arrow-left icon--sm-left icon--blue"}></i>{t('Back')}
            </div>}
            {props.showMenu && <Sidebar isNavigationBar={true} />}
        </div>
    );
}

NavigationBar.propTypes = {
    showBack: PropTypes.bool,
    showMenu: PropTypes.bool,
    usePadding: PropTypes.bool,
}

NavigationBar.defaultProps = {
    showBack: true,
    showMenu: true,
    usePadding: true,
}

export default NavigationBar;