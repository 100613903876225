import './landingpage.scss';
import React        from 'react'
import { isGerman } from '../Helper/Helper';
import {request}    from 'graphql-request'
import {
	telegramEndpoint,
	landingPageSendMailQuery
} from '../Helper/Api';
import Translations from '../Helper/Translation';
import RouterHelper from '../Helper/RouterHelper';
import { Link }          from "react-router-dom";



interface IState {
    email: string,
    name: string,
    phone: string,
    instagram: string,
    message: string,
    isPolicyAgree: boolean,
    isEmailValid: boolean,
    isNameValid: boolean,
    showErrors: boolean,
    showSuccessModal: boolean,
  }

  class Contact extends React.Component<{},IState> {
      constructor(props: any) {
          super(props);
          this.state = {...this.getClearState()}

          this.onChange     = this.onChange.bind(this);
          this.onFormSubmit = this.onFormSubmit.bind(this);
          this.isFormValid  = this.isFormValid.bind(this);
          this.sendMail     = this.sendMail.bind(this);
      }


    onChange(event: any) {
        const state: any = JSON.parse(JSON.stringify(this.state));
        const target     = event.target;
        const value      = target.type === 'checkbox' ? target.checked : target.value;

        state[event.target.name] = value;
        this.setState({...state, showErrors: false});
    }

    getClearState() {
        return {
            email:            "",
            name:             "",
            phone:            "",
            instagram:        "",
            message:          "",
            isPolicyAgree:    false,
            isEmailValid:     false,
            isNameValid:      false,
            showErrors:       false,
            showSuccessModal: false,
        }
    }

    sendMail() {
        const variables = {
            email: this.state.email, 
            name: this.state.name, 
            phoneNumber: this.state.phone, 
            instagramLink: this.state.instagram, 
            messageText: this.state.message
        };

        request(telegramEndpoint, landingPageSendMailQuery, variables).then((data: any) => {
            if (data && data.landingPage.sendEMail) {
                this.setState({...this.getClearState(), showSuccessModal: true});
            }
        });
    }

    isFormValid() {
        const validObject = {
            isEmailValid:     false,
            isNameValid:      false,
        };

        if (!this.state.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            validObject.isEmailValid = false;
        } else {
            validObject.isEmailValid = true;
        }

        if (this.state.name.length < 3) {
            validObject.isNameValid = false;
        } else {
            validObject.isNameValid = true;
        }

        const isValid = validObject.isEmailValid && validObject.isNameValid && this.state.isPolicyAgree;

        this.setState({...validObject, showErrors: !isValid});

        return isValid;
    }


    onFormSubmitMobile(event: any) {
        event.preventDefault();
        const scrollPoint = document.body.clientHeight - window.innerHeight;
        const current     = window.scrollY;
        if (((scrollPoint - 100) > current) && window.innerWidth < 768) {
            window.scrollTo({
                top: scrollPoint,
                left: 0,
                behavior: 'smooth'
              });
            return false;
        }
        
        if (!this.isFormValid()) {
            return;
        }
       this.sendMail();
    }

    onFormSubmit(event: any) {
        event.preventDefault();
        const scrollPoint = document.body.clientHeight - window.innerHeight;
        const current     = window.scrollY;
        if (((scrollPoint - 100) > current) && window.innerWidth < 768) {
            window.scrollTo({
                top: scrollPoint,
                left: 0,
                behavior: 'smooth'
              });
            return false;
        }
        
        if (!this.isFormValid()) {
            return;
        }
       this.sendMail();
    }
    
    getSuccessModal() {
        if (!this.state.showSuccessModal) {
            return null;
        }
        
        setTimeout(() => {
           this.setState({showSuccessModal: false})
        }, 3000);
        
        return (<div className="modal__wrapper">
            <div className="modal">
                <img className="modal__check" src="/images/check_green.svg" alt="check"/>
                <div className="modal__headline">{Translations.get('lp-contact-modal-headline')}</div>
                <div className="modal__subline">{Translations.get('lp-contact-modal-subline')}</div>
            </div>
        </div>);
    }

    getForm() {
        return (
            <form onSubmit={this.onFormSubmit} className="contact__form" noValidate>
                <div className="contact__form-item__wrapper">
                    <div className="contact__form-item">
                        <label className={"contact__form-label" + (!this.state.isEmailValid && this.state.showErrors ? " contact__form-label--error" : "")}>
                            {Translations.get('lp-contact-email')}*
                            <input type="email" placeholder={Translations.get('lp-contact-email')} name="email" value={this.state.email} onChange={this.onChange} className="contact__form-input" required />
                            <div className="contact__form-error">{Translations.get('lp-contact-email-error')}</div>
                        </label>
                    </div>
                    <div className="contact__form-item">
                        <label className={"contact__form-label" + (!this.state.isNameValid && this.state.showErrors ? " contact__form-label--error" : "")} >
                            {Translations.get('lp-contact-name')}*
                            <input type="text" placeholder={Translations.get('lp-contact-name')} name="name" value={this.state.name} onChange={this.onChange} className="contact__form-input" required />
                            <div className="contact__form-error">{Translations.get('lp-contact-name-error')}</div>
                        </label>
                    </div>
                    <div className="contact__form-item">
                        <label className="contact__form-label">
                            {Translations.get('lp-contact-phone')}
                            <input type="tel" pattern="[0-9]{4}[0-9]{7}" placeholder={Translations.get('lp-contact-phone')} name="phone" value={this.state.phone} onChange={this.onChange} className="contact__form-input" />
                        </label>
                    </div>
                    <div className="contact__form-item">
                        <label className="contact__form-label">
                            {Translations.get('lp-contact-instagram')}
                            <input type="url" placeholder={Translations.get('lp-contact-instagram')} name="instagram" value={this.state.instagram} onChange={this.onChange} className="contact__form-input" />
                        </label>
                    </div>
                </div>

                <div className="contact__form-item">
                    <label className="contact__form-label">
                        {Translations.get('lp-contact-message-label')}
                        <textarea placeholder={Translations.get('lp-contact-message')} name="message" value={this.state.message} onChange={this.onChange} className="contact__form-input contact__form-input--textarea" />
                    </label>
                </div>

                <label className={"contact__form-label contact__form-label--checkbox" + (!this.state.isPolicyAgree && this.state.showErrors ? " contact__form-label--error" : "")}>
                    <div className="contact__form-checkbox-wrapper">
                        <input type="checkbox" placeholder={Translations.get('lp-contact-message')} name="isPolicyAgree" checked={this.state.isPolicyAgree} onChange={this.onChange} className="contact__form-input contact__form-input--checkbox" />
                        <span className="contact__form-checkbox-clone"></span>
                        <span> 
                            {Translations.get('lp-contact-terms-1')} 
                            <Link to={isGerman() ? RouterHelper.Routes.DE.DATA_PROTECTION : RouterHelper.Routes.EN.DATA_PROTECTION} >{Translations.get('DataProtectionLink')}</Link>
                            {Translations.get('lp-contact-terms-2')}
                        </span>
                    </div>
                    <div className="contact__form-error">{Translations.get('lp-contact-terms-error')}</div>
                </label>
                <div className='contact__form-input--submit-wrapper'>
                    <input className="contact__form-input contact__form-input--submit" type="submit" value={Translations.get('lp-contact-submit')} />
                </div>
            </form>
        );
    }

    getBenefitsCard() {
        return (<div className={"contact" + (isGerman() ? " contact--add-on" :  "")}>
            {isGerman() ? <div className="constact__add-on">{Translations.get('lp-contact-add-on-1')}<br/>{Translations.get('lp-contact-add-on-2')}</div> : null}
            <div className="contact__headline">
                <img className="contact__headline-background" src="/images/landingpage/contact.png" alt="CONTACT" />
                {Translations.get('lp-contact-join-us')}<br/>
                {isGerman() ?  Translations.get('lp-contact-join-us-2') : ''}
            </div>
                {this.getForm()}
                {this.getSuccessModal()}
        </div>);
    }

    render() {
        return this.getBenefitsCard();
    }
}

export default Contact;