import api from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/less-loader/dist/cjs.js??ref--6-oneOf-3-2!./App.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};