import {matchPath} from "react-router";

const Routes = {
	ANY: {
		DEFAULT:                "/",
		SIGNUP:                 "/signup/",
		LOGIN:                  "/login/",
		LOGOUT:                 "/logout/",
		RESET:                  "/reset/",
		EMAIL_CONFIRMATION:     "/emailconfirmation/",
		NEWSLETTER_UNSUBSCRIBE: "/newsletterunsubscribe/",
		VIP:                    "/vip/",
		ABOS:                   "/subscriptions/",
		JUMP_START:             "/start/",
		TIP:					"/tip/",
	},
	DE:  {
		BUY_CONTENT:     "/kaufen/:contentId/",
		CONDITIONS:      "/agb/",
		DATA_PROTECTION: "/datenschutz/",
		IMPRINT:         "/impressum/",
		AGB:             "/agb/",
	},
	EN:  {
		BUY_CONTENT:     "/buy/:contentId/",
		CONDITIONS:      "/conditions/",
		DATA_PROTECTION: "/privacy-policy/",
		IMPRINT:         "/legal-notice/",
		AGB:             "/terms-of-service/",
	},
};

export default {
	Routes,
	isGermanPath(path: string): boolean {
		let i: keyof typeof Routes.DE;
		for (i in Routes.DE) {
			const found = Routes.DE[i];
			if (matchPath(path, found)) {
				return true;
			}
		}
		return false;
	}
};
