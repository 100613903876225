import './landingpage.scss';
import React        from 'react'
import Translations from '../Helper/Translation';

const Monetizing = () => {

    function getMessaging() {
        return <div className='lp-monetizing__bubbles-wrapper lp-monetizing__bubbles-wrapper--messages'>
        <div className='lp-monetizing__bubbles'>
            <img src="../images/Private_chat.png" alt="" className='lp-monetizing__bubble-icon lp-monetizing__bubble-icon--tablet' />
            <div className='lp-monetizing__bubble-gray__wrapper'>
                <div className='lp-monetizing__bubble lp-monetizing__bubble-gray'>
                    <img src="../images/Private_chat.png" alt="" className='lp-monetizing__bubble-icon lp-monetizing__bubble-icon--small' />
                    <span>{Translations.get('lp-header-bubble-message-1')}</span>
                </div>
            </div>
            <div className='lp-monetizing__bubble-colored__wrapper'>
                <div className='lp-monetizing__bubble lp-monetizing__bubble-colored'>
                    <span>{Translations.get('lp-header-bubble-message-2')}</span>
                    <img src="../images/EigeneNummer.png" alt="" className='lp-monetizing__bubble-icon' />
                </div>
            </div>
        </div>
    </div>;
    }

    function getMonotize() {
        return <div className='lp-monetizing__bubbles-wrapper lp-monetizing__bubbles-wrapper--monotize'>
        <div className='lp-monetizing__bubbles'>
            <div className='lp-monetizing__bubble-colored__wrapper'>
                <div className='lp-monetizing__bubble lp-monetizing__bubble-colored'>
                <img src="../images/Monitarizierung.png" alt="" className='lp-monetizing__bubble-icon' />
                    <span>{Translations.get('lp-header-bubble-monotize-1')}</span>
                </div>
            </div>
            <div className='lp-monetizing__bubble-gray__wrapper'>
                <div className='lp-monetizing__bubble lp-monetizing__bubble-gray'>
                <span>{Translations.get('lp-header-bubble-monotize-2')}</span>
                </div>
            </div>
        </div>
    </div>;
    }

    function getBot() {
        return <div className='lp-monetizing__bubbles-wrapper lp-monetizing__bubbles-wrapper--bot'>
        <div className='lp-monetizing__bubbles'>
            <div className='lp-monetizing__bubble-colored__wrapper'>
                <div className='lp-monetizing__bubble lp-monetizing__bubble-colored'>
                <img src="../images/Telegram_Bot.png" alt="" className='lp-monetizing__bubble-icon--small' />
                <span>{Translations.get('lp-header-bubble-bot-1')}</span>
                </div>
            </div>
            <div className='lp-monetizing__bubble-gray__wrapper'>
                <div className='lp-monetizing__bubble lp-monetizing__bubble-gray'>
                    <span>{Translations.get('lp-header-bubble-bot-2')}</span>
                    <img src="../images/Telegram_Bot.png" alt="" className='lp-monetizing__bubble-icon--tablet' />
                </div>
            </div>
        </div>
    </div>;
    }

    function getSocial() {
        return  <div className='lp-monetizing__bubbles-wrapper lp-monetizing__bubbles-wrapper--social'>
        <div className='lp-monetizing__bubbles lp-monetizing__bubble--social'>
        <img src="../images/social_media.png" alt="" className='lp-monetizing__bubble-icon--tablet' />
        <div className='lp-monetizing__bubble-gray__wrapper'>
                <div className='lp-monetizing__bubble lp-monetizing__bubble-gray'>
                    <span>{Translations.get('lp-header-bubble-social-1')}</span>
                    <img src="../images/social_media.png" alt="" className='lp-monetizing__bubble-icon--small' />
                </div>
            </div>
            <div className='lp-monetizing__bubble-colored__wrapper'>
                <div className='lp-monetizing__bubble lp-monetizing__bubble-colored'>
                <span>{Translations.get('lp-header-bubble-social-2')}</span>
                </div>
            </div>
        </div>
    </div>;
    }



    return <div className='lp-monetizing'>
            {getMessaging()}
            {getMonotize()}
            {getBot()}
            {getSocial()}
        </div>;
}

export default Monetizing;