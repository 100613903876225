import {useTranslation} from 'react-i18next';
import React, {useEffect} from 'react';
import Footer from '../Footer/Footer';
import './premium.scss';
import {AboFilter, queryAvailableAbosForGuest, queryGetFilteredAbos} from "../Helper/GraphQLHelper";
import {getGuest } from "../Helper/SessionHelper";
import {GuestAbo } from "../Helper/DataTypes";
import {getLanguage} from "../Helper/Helper";
import {useHistory} from "react-router-dom";
import {Header, getAboTile, createGuestAbos} from "../Helper/PremiumHelper";
import NavigationBar from '../Components/NavigationBar';
import {getExpectationsBackup} from '../Helper/data';
import {getBackground, onWindowResize} from "./PremiumHelper";
import {restCall} from "../Helper/Api";

type ExpectationsType = { [index: string]: Array<string> }

let interval: any = null;

function ExpectationTile(props: ExpectationsType) {
	const {t} = useTranslation();
	const items = props.expectations.map((expectation, i) =>
		<li className={"expectation-tile__list-item"} key={i}>{expectation}</li>
	);
	return (
		<div className={"expectation-tile"}>
			<div className={"expectation-tile__headline"}>{t('ExpectingYou')}</div>
			<ul className={"expectation-tile__list"}>
				{items}
			</ul>
		</div>
	)
}


function queryAbos(actorId: number, setter: Function) {
	const guest = getGuest();

	if (guest && guest.vxoneSessionId) {
		queryAvailableAbosForGuest(guest, actorId).then((res: any) => {
			if (res.data && res.data.guest && res.data.guest.availableAbos) {
				let abos = res.data.guest.availableAbos;
				abos = [...abos].sort((a: any, b: any) => a.abo.paymentInterval - b.abo.paymentInterval);
				createGuestAbos(abos, setter);
			}
		});
	} else {
		const filter = new AboFilter();

		filter.active = true;
		filter.actorId = actorId;
		filter.benefitTypes = ['Telegram'];
		filter.benefitSubTypes = ['CUSTOM'];
		queryGetFilteredAbos(filter).then((res: any) => {
			if (res.data.getFilteredAbos.items) {
				let abos = res.data.getFilteredAbos.items;
				abos = [...abos].sort((a: any, b: any) => a.paymentInterval - b.paymentInterval);
				const guestAbos = [];
				for (let i = 0; i < abos.length; i++) {
					const guestAbo = new GuestAbo();
					Object.assign(guestAbo, abos[i]);
					guestAbos.push(guestAbo);
				}
				console.log(guestAbos);
				setter(guestAbos);
			}
		});
	}

}

function Premium(props: any) {
	const data = {...props};

	const [abos, setAbos] = React.useState<Array<GuestAbo> | null>();
	const {t} = useTranslation();
	const history = useHistory();
	const [modalAboId, setModalAboId] = React.useState(0);
	const [showUnsubscribeConfirmationModal, setShowUnsubscribeConfirmationModal] = React.useState(false);
	const [backgroundImageUrl, setBackgroundImageUrl] = React.useState(getBackground(data));

	const guest = getGuest();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	useEffect(() => {
		if (!abos) {
			queryAbos(data.actorId, setAbos);
		}
	}, [abos, data.actorId]);

	if (!abos) {
		return null;
	}

	onWindowResize(() => {
			const tmp = getBackground(data);
			if (tmp !== backgroundImageUrl) {
				setBackgroundImageUrl(tmp);
			}
		}
	);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const communiPayStatus = urlParams.get('history');

	if (['avs_required', 'avs_pending'].indexOf(data.premiumStatus) > -1) {
		switch (communiPayStatus) {
			case 'finish':
			case 'pending':
				if (communiPayStatus === "finish" && data.premiumStatus === 'active') {
					break;
				}
				if (communiPayStatus === 'pending' && data.premiumStatus === 'avs_pending') {
					break;
				}

				if (interval === null) {
					interval = setInterval(() => {
						restCall('services/telegram/premiumStatus', data.accessToken, {actorId: data.actorId}, (result: any) => {
							if (result && result.success && result.data && result.data.premiumStatus) {
								if (result.data.premiumStatus !== data.premiumStatus) {
									props.setPremiumStatus(result.data.premiumStatus);
									clearInterval(interval);
									interval = null;
								}
							}
						});
					}, 1000);
				}
				break;
			case 'close':
			default:
				break;
		}
	}

	const updatePremiumStatus = () => {
		restCall('services/telegram/premiumStatus', data.accessToken, {actorId: data.actorId}, (result: any) => {
			if (result && result.success && result.data && result.data.premiumStatus) {
				props.setPremiumStatus(result.data.premiumStatus);
			}
		});
	};

	const style = {backgroundImage: `url(${backgroundImageUrl})`};

	let shownAbos = [];
	//let hasAbo = false;

	const hasAbos = !!abos.find(abo => {
		return abo.instances.find(instance => {
			if (instance.status !== 'STOPPED') {
				return true;
			}
			return false;
		});
	});

	for (let i = 0; i < abos.length; i++) {
		const currentInstance = abos[i].instances[abos[i].instances.length - 1];
		if (currentInstance && currentInstance.status !== 'STOPPED') {
			shownAbos.push(abos[i]);
		} else {
			abos[i].isOnlyPreview = guest && hasAbos ? true : false;
			shownAbos.push(abos[i]);
		}
	}

	if (shownAbos.length === 0) {
		shownAbos = abos;
	}

	const Abos = (
		<div className={"abo-tile__container content__wrapper--premium abo-tile__container--multiple"}>
			{shownAbos.map((abo: GuestAbo, i: number) => getAboTile(abo, queryAbos, data, setAbos, history, t, modalAboId, setModalAboId, updatePremiumStatus, setShowUnsubscribeConfirmationModal, showUnsubscribeConfirmationModal))}
		</div>
	);

	const hasExpecations = props.bulletTextList && props.bulletTextList.bulletTexts && props.bulletTextList.bulletTexts.length > 0;
	const expectations = hasExpecations ? props.bulletTextList.bulletTexts.map((item: any) => item.text) : getExpectationsBackup()[getLanguage()];
	return (
		<div className={"premium"} style={style}>
			<NavigationBar showMenu={true} />
			<div className={"premium__wrapper"}>
				<Header logo={data.premiumLogoUrl} isMySubs={false} t={t} />
				{Abos}
				<div className='abo-tile__container abo-tile__button-info' style={{textAlign: 'left'}}>* {t('inc-vat')}</div>
				<ExpectationTile expectations={expectations} />
				<Footer {...data} />
			</div>
		</div>
	);
}

export default Premium;