import './tip.scss';
import React         from 'react'
import arrowBlack    from '../images/arrow-right--black.svg';
import arrowWhite    from '../images/arrow-right--white.svg';
import check         from '../images/success-green.svg';
import error         from '../images/error.svg';
import {restCall, restCallGet}    from '../Helper/Api';
import {getLanguage} from '../Helper/Helper';
import Translations  from '../Helper/Translation';

const MAX         = 150;
const MIN         = 3; 
const DECIMAL     = 2;
const TIPS        = [5, 10, 25];
let interval: any = null;
interface IProps {
    accessToken:  string;
    guest:        any;
    setGuest:     any;
    actorId:      number;
    modelName:    string;
    usdMode:      boolean;
    avs:          boolean;
    match:        any;
    peerName:     string;
    removeParam:  any;
}

interface IState {
    tip:           string;
    isManually:    boolean;
    inputFocus:    boolean;
    inputError:    string;
    pin:           number;
    showSpinner:   boolean;
    avsIsPending:  boolean;
    avsIsRejected: boolean;
  }

export default class AddTip extends React.Component<IProps, IState> {
    private inputRef: any;
    private buttonRef: any;
    private confirmationRef = React.createRef<HTMLInputElement>();
    
    constructor(props: IProps) {
        super(props);
        const urlParams = new URL(window.location.href);
        const tip = parseFloat(urlParams.searchParams.get('amount')!) > MAX ? '0' :  parseFloat(urlParams.searchParams.get('amount')!).toFixed(2);
        this.state = { 
            tip:           urlParams.searchParams.get('amount') ? tip : '',
            isManually:    !!urlParams.searchParams.get('amount') && !TIPS.includes(parseFloat(urlParams.searchParams.get('amount')!)),
            inputFocus:    false,
            inputError:    '',
            pin:           0,
            showSpinner:   false,
            avsIsPending:  false,
            avsIsRejected: false,
        }

        this.onInputClick    = this.onInputClick.bind(this);
        this.onInputChange   = this.onInputChange.bind(this);
        this.addTip          = this.addTip.bind(this);
        this.copyPin         = this.copyPin.bind(this);
        this.onInputKeyDown  = this.onInputKeyDown.bind(this);
        this.onInputFocus    = this.onInputFocus.bind(this);
        this.onInputFocusOut = this.onInputFocusOut.bind(this);
        this.checkAvs        = this.checkAvs.bind(this);

        this.inputRef = null;
        this.buttonRef = null;
    }

    componentDidMount() {
        this.checkGuestExists(false, 1);
        this.props.avs && this.checkAvs();
    }

    checkAvs() {
        const clearAll = () => {
            clearInterval(interval);
            interval = null;
            this.props.removeParam('history');
        }
        if (this.props.guest && !this.props.guest.hasAvs) {
            if (interval === null) {
                interval = setInterval(() => {
                    restCallGet('avs/statusCommuniPay', this.props.accessToken, (response: any) => {
                        if (response.data.fsk18) {
                            this.setState({avsIsPending: false, avsIsRejected: false}, () => {
                                const guest  =  {...this.props.guest};
                                guest.hasAvs = true;
                                clearAll();
                                this.props.setGuest(guest);
                            })
                        } else if (response.data.status === 'pending') {
                            this.setState({avsIsPending: true, avsIsRejected: false});
                        } else if (response.data.status === 'rejected') {
                            clearAll();
                            this.setState({avsIsRejected: true, avsIsPending: false});
                        } else {
                           clearAll();
                        }
                    });
                }, 1000);
            }            
        }
    }

    setTip(tip: string, isManually: boolean = false, inputError: string = '') {
        if (!isManually) {
            this.inputRef.style.display = 'none';
        }
        this.setState({tip: tip, isManually, inputError});
    }

    onInputChange(e: any) {
        let tip    = e.target.value;
        let error  = '';

        if (tip.length === 0) {
            this.inputRef.style.width = "4ch";
            this.setTip(tip, true, error);
        } else {
            
            if (tip.split('.')[0].toString().length > MAX.toString().length || tip > MAX) {
                error = Translations.get('tip-tipping-error-max', {amount: MAX + this.getCurrency()});
                this.setTip(this.state.tip, true, error);
                return;
            }
    
            if (tip.split('.')[1] && tip.split('.')[1].toString().length > DECIMAL) {
                return;
            }
    
            if (this.inputRef) {
                this.inputRef.style.width = (tip.length + 1) + "ch";
            }
            
            if (tip < MIN) {
                error = Translations.get('tip-tipping-error-min', {amount: MIN + this.getCurrency()});
                this.setTip(tip, true, error)
                return;
            }
           
            this.setTip(tip, true, error);
        }
    }

    onInputKeyDown(event: any) {     
        if(event.which === 8 || event.which === 188 || event.which === 190 || event.which === 110 || !isNaN(Number(event.key))) {

        } else {
            event.preventDefault();
        }
    }

    onInputClick() {
        const ref = this.inputRef;
        if(ref) {
            this.setState({inputFocus: true, isManually: true, tip: this.state.isManually ? this.state.tip : '0'}, () => {
                ref.style.display = 'block';
                ref.focus();
            });
        }
    }

    onInputFocus() {
        if (window.innerWidth < 768) {
            window.scrollTo({
                top: this.inputRef.getBoundingClientRect().top - 100 + window.scrollY,
                behavior: 'smooth'
            });
            this.buttonRef.style.bottom   = '-85px';
            this.buttonRef.style.position = 'absolute';
        }
    }
    
    onInputFocusOut() {
        if (window.innerWidth < 768) {
            this.buttonRef.style.bottom   = '0';
            this.buttonRef.style.position = 'fixed';
        }
    }

    getTipButtons() {
        return (
            <div className="tip__buttons">
                { TIPS.map(tip => (
                        <div key={tip} className={"tip__button-tip" + (parseFloat(this.state.tip) === tip && !this.state.isManually ? " tip__button-tip--active" : "")} onClick={() =>this.setTip(tip.toString())}>{tip} {this.getCurrency()}</div>
                    ))}
            </div>
        )
    }

    checkGuestExists(hasIntervall: boolean = false, pin: number) {
        if (this.props.accessToken && (this.props.guest && !this.props.guest.hasTelegramActor) && pin > 0) {
            const data = {
                "actorId": this.props.actorId,
            };
            restCall('services/telegram/existsGuest', this.props.accessToken, data, (result: any) => {
                if(result && result.success) {
                    if (!result.data.existsGuest) {
                        if (!hasIntervall) {
                            this.setState({pin});
                            setInterval(() => {
                                this.checkGuestExists(true, pin);
                            }, 2000)
                        }
                    } else {
                        const loginData = {
                            uhash: this.props.guest.uhash
                        };
                        restCall('login', this.props.accessToken, loginData, (data: any) => {
                            if (data.success) {
                                this.props.setGuest(data.data, () => {
                                    this.setState({pin: 0});
                                });
                            }
                        });
                    }
                }
            })
        }
    }

    addTip(e:any, useOneClick: boolean = false) {
        e.preventDefault();
        if (this.props.accessToken && this.props.guest) {
            if (!(this.props.guest && this.props.guest.hasTelegramActor) && (this.props.guest && this.props.guest.hasAvs)) {
                const data = {
                    returnUrl: window.location.href + '?amount=' + this.state.tip,
                };
                restCall('services/telegram/requestPin', this.props.accessToken, data, (result: any) => {
                    if (result && result.success) {
                        this.checkGuestExists(false, result.data.pin);
                    }
                });
            } else {           
                this.setState({showSpinner: true}, () => {
                    const data = {
                        "amount":    parseFloat(this.state.tip),
                        "returnUrl": window.location.href + '?amount='+this.state.tip,
                        "tipHash":   this.props.match.params.id,
                        "lang":      getLanguage(),
                        "oneclick":  useOneClick,
                    };
                    
                    restCall('services/telegram/tip/initPayment', this.props.accessToken, data, (result: any) => {
                        if (result.data && result.data.success) {
                            if (useOneClick) {
                                window.location.href = window.location.href + '?history=finish&amount='+this.state.tip;
                            } else {
                                window.location.href = result.data.checkoutUrl;
                            }
                        } else if (result.error.code === 1) {
                            window.location.href = window.location.origin + '/login/?destination=tip&amount' + this.state.tip;
                        } else if (result.data && result.data.checkoutUrl){
                            window.location.href = result.data.checkoutUrl;
                        }
                    });
                })
            }
        } else {
            window.location.href = window.location.origin + '/login/?destination=tip&amount='+ this.state.tip;
        }
    }

    getCurrency() {
        return this.props.usdMode ? '$' : '€';
    }

    onFormSubmit(e: any) {
        e.preventDefault();
    }

    copyPin() {
    navigator.clipboard.writeText(this.state.pin.toString());
        if (this.confirmationRef) {
            this.confirmationRef.current?.classList.add('tip__modal-confirmation--active');
            setTimeout(() => {
                this.confirmationRef.current?.classList.remove('tip__modal-confirmation--active');
            },3000);
        }
    }

    setInputRef(ref: any) {
        if (ref) {
            if (this.state.isManually && !this.inputRef) {
                ref.style.display = 'block';
                ref.style.width = (this.state.tip.length + 1) + "ch";
            }
            this.inputRef = ref;
        }
    }
    
    setButtonRef(ref: any) {
        if (ref) {
            this.buttonRef = ref;
        }
    }

    getAddTip() {
        const additionalClass   = this.state.inputError && this.state.isManually ? 'error' : (!this.state.inputError && this.state.isManually  && parseFloat(this.state.tip) > 3 ? 'success' : '');
        const tip               = typeof this.state.tip === 'number' ? this.state.tip : parseFloat(this.state.tip);
        const isActiveButton    = !this.state.avsIsPending && !this.state.showSpinner && (parseFloat(this.state.tip) >= MIN || tip === 1.2);
        const showChangePayment = this.props.guest && this.props.guest.isPayingCustomer && isActiveButton;
        const needAVS           = this.props.guest && !this.props.guest.hasAvs;
        return (
            <form className={"tip" + (showChangePayment ? " tip--no-padding" : "")} onSubmit={this.onFormSubmit}>
                <div className="tip__headline"><span role="img" aria-label="money">💸</span> {Translations.get('tip-tipping-boost')}</div>
                <p className="tip__text">{Translations.get('tip-tipping-boost-text')}</p>
                <div className="tip__input-container">
                    {this.state.showSpinner && (this.props.guest &&this.props.guest.hasAvs) && <div className="tip__spinner-wrapper">
                        <span className="spinner" key="1" />
                        <div className="ranking__update">{Translations.get('tip-tipping-boost-spinner')}</div>
                    </div>}
                    {this.getTipButtons()}
                    <div className={"tip__input-wrapper " + (this.state.isManually ? ' tip__input-wrapper--active ' : '')+ additionalClass} onClick={this.onInputClick}>
                        <input type="number" min="0" value={this.state.tip === '0' ? '' : this.state.tip} pattern="[0-9*]" inputMode="decimal" onChange={this.onInputChange} placeholder={'0,00'} className={"tip__input"} ref={(ref) => this.setInputRef(ref)} onKeyDown={this.onInputKeyDown} onFocus={this.onInputFocus} onBlur={this.onInputFocusOut}/>
                        {!this.state.isManually && <span>{Translations.get('tip-tipping-manually')}</span>}
                        {this.state.isManually  && <span>{this.getCurrency()}</span>}
                        {additionalClass && !this.state.showSpinner && <div className={"tip__input-message " + additionalClass}>{this.state.inputError}</div>}
                        {additionalClass === 'success' && <img src={check} className={"tip__input-icon"} alt="" />}
                        {additionalClass === 'error' && <img src={error} className={"tip__input-icon"} alt="" />}
                    </div>
                    {showChangePayment && <div className="tip__change-payment tip__change-payment--mobile" onClick={(e) => this.addTip(e)}>{Translations.get('ChangePayment')}</div>}
                </div>
                <div className='tip__text'>{Translations.get('inc-vat')}</div>
                <div className="tip__button-wrapper" ref={(ref) => this.setButtonRef(ref)}>
                    <button className={"tip__button" + (isActiveButton ? "" : " tip__button--disabled")} disabled={!isActiveButton} onClick={(e) => this.addTip(e, this.props.guest && this.props.guest.isPayingCustomer)}>
                        {!this.state.showSpinner && !this.state.avsIsPending && Translations.get('tip-tipping-activate')}
                        {(this.state.showSpinner || this.state.avsIsPending) && <span className="spinner spinner--black" key="1" />}
                        <img src={isActiveButton ? arrowWhite :  arrowBlack} alt=">" className="tip__button-arrow" />
                    </button>
                    {isActiveButton && this.state.avsIsRejected && <div className="tip__avs-ready">{Translations.get('AvsRejected')}</div>}
                    {this.state.avsIsPending && <div className="tip__avs-ready">{Translations.get('AvsPending')}</div>}
                    {isActiveButton && !this.state.avsIsPending && needAVS && <div className="tip__avs-ready">{Translations.get('AvsReady')}</div>}
                    {!needAVS && showChangePayment &&  <div className="tip__change-payment tip__change-payment--desktop" onClick={(e) => this.addTip(e)}>{Translations.get('ChangePayment')}</div>}
                </div>
                {this.state.pin > 0 && <div className="tip__modal-wrapper"><div className="tip__modal">
                    <div className="tip__modal-confirmation" ref={this.confirmationRef}><img src={check} className={"tip__modal-confirmation__icon"} alt="" />{Translations.get('tip-modal-copy-confirm')}</div>
                    <div className="tip__modal-headline">{Translations.get('tip-tipping-boost')}</div>
                    <div className="tip__modal-text">{Translations.get('tip-modal-text', {modelname: this.props.modelName})}</div>
                    <div className="tip__modal-pin">{this.state.pin}</div>
                    <div className="tip__modal-copy" onClick={this.copyPin}>{Translations.get('tip-modal-copy')}</div>
                    <div className="tip__button-wrapper">
                        <a className="tip__button" href={`https://t.me/${this.props.peerName}`} rel="noopener noreferrer" target="_blank">
                                        {Translations.get('tip-modal-button')} <img src={arrowWhite} alt=">" className="tip__button-arrow" />
                        </a>
                    </div>
                </div></div>}
            </form>
        );
    }

    render() {
        return this.getAddTip();
    }
}
