import {VXPay, VXPayConfig} from "vxpay-js";
import {getGuest}           from "./SessionHelper";

let _vxpay: any = null;

function initVXPay(actorId: number, language: string) {
	if (_vxpay) {
		return;
	}

	const accessToken = sessionStorage.getItem('accessToken');

	let vxpayConfig = new VXPayConfig(window);

	vxpayConfig.pfm      = '1505';
	vxpayConfig.pfmsub   = 'h' + actorId;
	vxpayConfig.logging  = true;
	vxpayConfig.language = language.toUpperCase();

	if (accessToken && getGuest()) {
		vxpayConfig.token = accessToken;
	}

	_vxpay = new VXPay(vxpayConfig);
}

function setAccessToken(token: string) {
	_vxpay._config.token = token;
}

function getVXPay() {
	return _vxpay;
}

/**
 *
 * @param aboId
 */
function openAboBooking(aboId: number) {
	if (_vxpay._config.token !== '') {
		_vxpay.openAboOverview({aboId: aboId});
	} else {
		// try to autoset existing token
		const accessToken = sessionStorage.getItem('accessToken');
		if (accessToken && getGuest()) {
			setAccessToken(accessToken);
			_vxpay.openAboOverview({aboId: aboId});
		}
	}
}

export {initVXPay, setAccessToken, getVXPay, openAboBooking}