let premium: any = {};

const getExpectationsBackup = () => {
    premium = {
            'de': ['1Regelmäßig hardcore Fotos & Videos aus meinem Alltag, direkt auf Dein Handy', 'Exklusive News und Gutscheincodes für meine Shops', 'Versaute Nachrichten und pikante Fotos für Dein Kopfkino'], 
            'en': ['Hardcore photos & videos from my everyday life directly onto your phone', 'Exclusive news and coupon codes for my content', 'Dirty messages and naughty photos to let your imagination run wild']
        };
    return premium;
}

export {
    getExpectationsBackup
};