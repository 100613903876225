import './landingpage.scss';
import React        from 'react'
import Telegram     from './Telegram';
import Monetizing   from './Monetizing';
import Benefit      from './Benefit';
import Features     from './Features';
import Manual       from './Manual';
import Testimonials from './Testimonials';
import Contact      from './Contact';
import FooterMeta   from '../Footer/FooterMeta';
import { getLanguage, isGerman } from '../Helper/Helper';

interface IProps {
    changeLanguage: any,
}

 class Landingpage extends React.Component<IProps> {
    render() {
        const changeLanguage = (language: string = 'de') => {
            this.props.changeLanguage(language);
        }

        return (
            <div className="landingpage">
                <div className="language-selector">
                    <span className={"languagel-selector__item" + (getLanguage() === 'en' ? " languagel-selector__item--active" : "" )} onClick={() => changeLanguage('en')}>EN</span> 
                    <span className={"languagel-selector__item" + (getLanguage() === 'de' ? " languagel-selector__item--active" : "" )} onClick={() => changeLanguage('de')}>DE</span>
                </div>
                <Telegram isGerman={isGerman()} />
                <Monetizing />
                <Benefit />
                <Features />
                <Manual />
                <Testimonials />
                <Contact /> 
                <FooterMeta showConditions={false} />
            </div>
        )
    }
}

export default Landingpage;