import './landingpage.scss';
import React        from 'react'
import Translations from '../Helper/Translation';
import {isGerman}   from '../Helper/Helper';


interface IProps {
    isGerman: boolean,
}
interface IState {
    videoIsPlaying: boolean,
    isMobile:       boolean,
    isDe:           boolean,
  }

class Telegram extends React.Component<IProps,IState> {
    videoRef: any;
    constructor(props: any) {
        super(props);
        this.state = {
            videoIsPlaying: false,
            isMobile:       window.innerWidth < 768,
            isDe:           isGerman(),
        }

        this.toggleVideo  = this.toggleVideo.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.videoRef = null;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    }

    shouldComponentUpdate(nextProps: any) {
        if (nextProps.isGerman !== this.props.isGerman) {
            this.setState({videoIsPlaying: false});
        }
        return true;
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const isMobile = window.innerWidth < 768;
        this.setState({isMobile, videoIsPlaying: this.state.isMobile !== isMobile ? false : this.state.videoIsPlaying});
    }
    
    handleScroll() {
        if (window.scrollY > window.innerHeight) {
            this.state.videoIsPlaying && this.videoRef && this.videoRef.pause();
            this.setState({videoIsPlaying: false});
        }
    }

    toggleVideo() {
        !this.state.videoIsPlaying && this.videoRef && this.videoRef.play();
        this.state.videoIsPlaying && this.videoRef && this.videoRef.pause();
        this.setState({videoIsPlaying: !this.state.videoIsPlaying});

        const endedlistener = () => {
            this.videoRef.load();
            this.videoRef.removeEventListener('ended', endedlistener);
            this.setState({videoIsPlaying: false});
        }
        this.videoRef.addEventListener('ended',endedlistener,false);
        
        const pausedlistener = () => {
            this.videoRef.removeEventListener('pause', pausedlistener);
            this.setState({videoIsPlaying: false});
        }
        this.videoRef.addEventListener('pause',pausedlistener,false);
    }

    onButtonClick() {
        window.scrollTo({
            top: document.body.clientHeight - window.innerHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    getHeaderSection() {
        const videoElement = <video controls={this.state.videoIsPlaying} className="lp-header__video"  src={`/images/videos/${isGerman() ? 'spot_de.mp4' : 'spot_en.mp4'}`} id="test" ref={ref => this.videoRef = ref} crossOrigin="anonymous"></video>;
        if (this.state.isMobile) {
            return <div className={'lp-header__mobile'  + (this.state.videoIsPlaying ? ' lp-header--video-playing' : '')} key="mobile">
                <div className="lp-header__video-wrapper">
                    {videoElement}
                    <img className="lp-header__play" src="/images/play.svg" alt=">" onClick={this.toggleVideo} />
                </div>
                <svg width="100%" height="100%" viewBox="0 0 500 65" preserveAspectRatio="xMinYMid meet" xmlns="http://www.w3.org/2000/svg">
                    <text x="0" y="60" fontSize="65" fill="black" className="lp-header__headline">
                        {Translations.get("lp-header-headline")}
                    </text>
                </svg>
                <div className="lp-header__text">{Translations.get("lp-header-text")}</div>
                <div className="lp-header__subline">{Translations.get("lp-header-subline")}</div>
                <ul className="lp-header__list">
                    <li className="lp-header__list-item"><span>+</span>{Translations.get("lp-header-list-item-1")}</li>
                    <li className="lp-header__list-item"><span>+</span>{Translations.get("lp-header-list-item-2")}</li>
                    <li className="lp-header__list-item"><span>+</span>{Translations.get("lp-header-list-item-3")}</li>
                </ul>
                {isGerman() ? <img className="lp-header__image" src="/images/VXModelbot2DE.png" alt="" /> : <img className="lp-header__image" src="/images/VXModelbotEN.png" alt="" /> }
            </div>;
        }

        return <div className={'lp-header__desktop'  + (this.state.videoIsPlaying ? ' lp-header--video-playing' : '')} key="desktop">
            <figure className="lp-header__video-figure">
                {videoElement}
                <figcaption className='lp-header__video-caption'>
                    <div className='lp-header__headline'>
                        {Translations.get("lp-header-headline")}
                    </div>
                    <div className="lp-header__text">{Translations.get("lp-header-text")}</div>
                    <div className="lp-button" onClick={this.onButtonClick}>{Translations.get('lp-header-button-text')}</div>
                </figcaption>
                <img className="lp-header__play" src="/images/play.svg" alt=">" onClick={this.toggleVideo} />
            </figure>
            <div className='lp-header__grid'>
                <div className='lp-header__grid-left'>
                    <div className="lp-header__subline">{Translations.get("lp-header-subline")}</div>
                    <ul className="lp-header__list">
                        <li className="lp-header__list-item"><span>+</span>{Translations.get("lp-header-list-item-1")}</li>
                        <li className="lp-header__list-item"><span>+</span>{Translations.get("lp-header-list-item-2")}</li>
                        <li className="lp-header__list-item"><span>+</span>{Translations.get("lp-header-list-item-3")}</li>
                    </ul>
                    <img className="lp-header__paper" src="/images/Message.png" alt="" />
                </div>
                {isGerman() ? <img className="lp-header__image" src="/images/VXModelbot2DE.png" alt="" /> : <img className="lp-header__image" src="/images/VXModelbotEN.png" alt="" /> }
            </div>
        </div>;
    }

    render() {
        return <section className='lp-header'>
            {this.getHeaderSection()}
            <div className='lp-header__monetizing-wrapper'>
                <div className='lp-header__monetizing-messaging'>{Translations.get('lp-header-messageing-meets')}</div>
                <div className='lp-header__monetizing'>{Translations.get('lp-header-monetizing')}</div>
                <div className='lp-header__monetizing-level'>{Translations.get('lp-header-level-1')}</div>
                <div className='lp-header__monetizing-level'>{Translations.get('lp-header-level-2')}</div>
            </div>
        </section>;
    }
}

export default Telegram;