import React, { useEffect } from 'react';
import NavigationBar        from '../Components/NavigationBar';
import LegalTexts           from './LegalTexts';

function Imprint({theme = '', showMenu = true, product = "TEL"}) {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className={"terms " + theme}>
			<NavigationBar showMenu={showMenu} />
            <LegalTexts type="IMP" product={product} />
        </div>
    )
}

export default Imprint;