import './landingpage.scss';
import React        from 'react'
import Translations from '../Helper/Translation';

class Benefit extends React.Component {

    getBenefitsCardItems() {
        const items = [
            {
                icon: 'socialsafe',
                text: Translations.get('lp-benefit-item-1-text'),
                color: 'blue'
            },            
            {
                icon: 'phone',
                text: Translations.get('lp-benefit-item-2-text'),
                color: 'purple'
            },
            {
                icon: 'support',
                text: Translations.get('lp-benefit-item-3-text'),
                color: 'purple-blue'
            },
            {
                icon: 'dummy',
            },
            {
                icon: 'dummy',
            },
            {
                icon: '11',
                text: Translations.get('lp-benefit-item-4-text'),
                color: 'purple-blue'
            },
            
            {
                icon: 'secure',
                text: Translations.get('lp-benefit-item-5-text'),
                color: 'purple'
            },
            {
                icon: 'referral',
                text: Translations.get('lp-benefit-item-6-text'),
                color: 'blue'
            }
        ];

        return items.map((item,index) => (
                    <li className={"lp-benefits__list-item lp-benefits__list-item--" + item.icon } key={index}>
                        <div className={'lp-benefits__list-item__icon-wrapper lp-benefits__list-item__icon-wrapper--'+item.color}>
                            {item.icon !== 'dummy' && <img src={`../images/${item.icon}.svg`} alt={item.text} className="lp-benefits__list-item__icon" />}
                        </div>
                        <div className="lp-benefits__list-item__text">{item.text}</div>
                    </li>
                ));
    }


    render() {
        return <div className='lp-benefits'>
            <div className='lp-benefits__headline'>BENEFITS</div>
            <ul className='lp-benefits__list'>
                {this.getBenefitsCardItems()}
            </ul>
        </div>;
    }
}

export default Benefit;