import './landingpage.scss';
import React        from 'react'
import {isGerman}   from '../Helper/Helper';
import Translations from '../Helper/Translation';

interface IProps {
    t?: any;
  }
  
  interface IState {
    isSliderWrapperSet: boolean,
    currentSlide:       number,
    isMobile:           boolean,
    isTablet:           boolean,
    isDesktop:          boolean,
    windowWidth:        number,
  }

let sliderItemsLength = 0;
class Testimonials extends React.Component<IProps, IState> {
    private sliderWrapperRef: any;
    private sliderRef: any;
    constructor(props: any) {
        super(props);

        const {isMobile, isTablet, isDesktop, windowWidth} = this.getView();

        this.state = {
            isSliderWrapperSet: false,
            currentSlide: 1,
            isMobile,
            isTablet,
            isDesktop,
            windowWidth,
        }

        this.slideLeft       = this.slideLeft.bind(this);
        this.slideRight      = this.slideRight.bind(this);
        this.getView         = this.getView.bind(this);
        this.onWindowResize  = this.onWindowResize.bind(this);
        this.onWrapperScroll = this.onWrapperScroll.bind(this);

        this.sliderWrapperRef = null;
        this.sliderRef        = null;
    }

    componentDidMount() {
       window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize() {
        const {isMobile, isTablet, isDesktop, windowWidth} = this.getView();
        if (!this.state.isMobile && isMobile) {
            this.setState({isMobile: true, isTablet: false, isDesktop: false});
        } else if (!this.state.isTablet && isTablet) {
            this.setState({isMobile: false, isTablet: true, isDesktop: false});
        } else if (!this.state.isDesktop && isDesktop) {
            this.setState({isMobile: false, isTablet: false, isDesktop: true});
        } else {
            this.setState({windowWidth});
        }
    }

    getView() {
        const windowWidth = window.innerWidth;
        let isMobile      = false;
        let isTablet      = false;
        let isDesktop     = false;

        if (windowWidth < 768) {
            isMobile = true;
        } else if (windowWidth < 992) {
            isTablet = true;
        } else {
            isDesktop = true;
        }

        return {isMobile, isTablet, isDesktop, windowWidth};
    }

    getSliderItems() {
        const items = [
            {
                video: isGerman() ? '1_de.mp4' :  '1_en.mp4',
                image: '/images/landingpage/maria.png',
                name: 'Maria Hering',
                text: Translations.get('lp-testimonial_1'),
                poster: isGerman() ? '1_de.png' :  '1_en.png',
                isDummy: false,
            },
            {
                video: isGerman() ? '2_de.mp4' :  '2_de.mp4',
                image: 'https://vx.vxcdn.org/u/1582031/s/60836/p/8002737/1280.jpg?8299-8fe97a4f677203b9',
                poster: isGerman() ? '2_de.png' :  '2_de.png',
                name: 'Aische Pervers',
                text: Translations.get('lp-testimonial_2'),
                isDummy: false,
            }
        ]; 

        const onClick = (e: any, videoId: any) => {
           const videoElement  = document.getElementById(videoId) as HTMLVideoElement; 
           const buttonElement = e.target;

           if (videoElement.paused) {
               videoElement.play();
               buttonElement.style.opacity = "0";
               
               const endedlistener = () => {
                    videoElement.load();
                    buttonElement.style.opacity = "1";
                    videoElement.removeEventListener('ended', endedlistener);
                }
                videoElement.addEventListener('ended',endedlistener,false);
           } else {
               videoElement.pause();
               buttonElement.style.opacity = "1";
           }
        };

        const filteredItems = items.filter(item => item.text);

        if (this.state.isDesktop && filteredItems.length > 1) {
            const firstDummy    = {...filteredItems[0]};
            const lastDummy     = {...filteredItems[filteredItems.length -1 ]};
    
            firstDummy.isDummy = true;
            lastDummy.isDummy  = true;
    
            filteredItems.unshift(lastDummy);
            filteredItems.push(firstDummy);
        }

        const videoStyle = this.state.isMobile ? {height: 'auto'} : {height: '560px'};
        const element = filteredItems.map((item, index) => {
             return(<div className="slider-item" key={index}>
                <div className="slider-item__video-wrapper">
                    <div onClick={(e) => onClick(e, item.video)} className="slider-video__control-wrapper">
                        <img className="slider-video__control" src="/images/play.svg" alt=">" />
                        { this.state.isMobile && <div className="slider-video__actor">
                        <div className="slider-item__image" style={{backgroundImage: `url(${item.image})`}}></div>
                        <div className="slider-item__name">{item.name}</div>
                        </div>
                        }
                    </div>
                    <video style={videoStyle} poster={`/images/videos/${item.poster}`} src={`/images/videos/${item.video}`} id={item.isDummy ? '' : item.video} className={`slider-item__video ${item.video}`} crossOrigin="anonymous"></video>
                </div>
                <div className="slider-item__text-wrapper">
                    <div className="slider-item__text">
                        {item.text}
                    </div>
                    {!this.state.isMobile && <div className="slider-item__image" style={{backgroundImage: `url(${item.image})`}}>
                    </div>}
                    {!this.state.isMobile && <div className="slider-item__name">
                        {item.name}
                    </div>}
                </div>
            </div>)
        });
        return element;
    }

    getSlideElements() {
        const buttonElements = (document.querySelectorAll('.slider-video__control-wrapper') as NodeListOf<HTMLElement>);
        const videoElements  = (document.querySelectorAll('.slider-item__video') as NodeListOf<HTMLVideoElement>);  
        return {buttonElements, videoElements};
    }

    stopVideoElements(){
        const  {buttonElements, videoElements} = this.getSlideElements();
        for (const video of videoElements as any){ 
            if(video.currentTime > 0) {
                video.pause();
                video.load();
            }
        }   
        
        for (const button of buttonElements as any ) {
            button.style.opacity = '1';
        }
    }

    slideLeft() {
        const wrapperWidth = this.sliderWrapperRef.clientWidth;
        const slide        = (this.state.currentSlide)  <=  0 ? sliderItemsLength - 1 : this.state.currentSlide - 1;
        this.sliderWrapperRef.scrollTo({
            left: ((slide) * wrapperWidth),
            behavior: 'smooth'
          });
        
          if (slide === 0) {
              this.setState({currentSlide: sliderItemsLength - 2}, () => 
              setTimeout(() => {
                this.sliderWrapperRef.scrollTo({
                    left: ((sliderItemsLength - 2) * wrapperWidth)
                    });
                }, 500)
            );
            
        } else {
            this.setState({currentSlide: slide});
        }
    }
   
    slideRight() {      
        const wrapperWidth = this.sliderWrapperRef.clientWidth;
        let slide          = this.state.currentSlide + 1  ===  sliderItemsLength ? 0 : this.state.currentSlide + 1;

        this.sliderWrapperRef.scrollTo({
            left: ((slide) * wrapperWidth),
            behavior: 'smooth'
          });
        if (slide + 1 === sliderItemsLength) {
            this.setState({currentSlide: 1} , () => {
                setTimeout(() => {
                this.sliderWrapperRef.scrollTo({
                    left: wrapperWidth
                    });
                }, 500)
            });
        } else {
            this.setState({currentSlide: slide});
        }
    }

    onWrapperScroll() {
        if (this.state.isMobile) {
            const itemLength = sliderItemsLength;
            const bound      = this.sliderRef.getBoundingClientRect();
            const itemWidth  = (bound.width - ((itemLength - 1) * 20)) / itemLength;
            const left       = ((bound.x < 0 ? (bound.x * -1) : bound.x) + 45 + (itemLength * 20));
            const slide      = Math.ceil(left / itemWidth);
            if (this.state.currentSlide !== slide) {
               this.setState({currentSlide: slide});
            }
        } else if (this.state.isTablet) {
            const itemLength = this.sliderWrapperRef.clientWidth - 80;
            const bound      = this.sliderRef.getBoundingClientRect();
            const slide = Math.round(bound.x / ((itemLength) * -1));
            if (this.state.currentSlide !== slide + 1) {
               this.setState({currentSlide: slide + 1});
            }
        }
        this.stopVideoElements();
    }

    slideTo(slide: number) {
        if (this.state.isMobile) {
            const left = (((slide - 1) * 20) / 2) + (280 * (slide - 1)) + (slide - 1 <= 0 ? -45 : ((slide - 1) * 10) / 2);
                this.sliderWrapperRef.scrollTo({
                            left,
                            behavior: 'smooth'
                });
        } else if (this.state.isTablet) {
            const left = ((slide - 1) * this.sliderWrapperRef.clientWidth);
            this.setState({currentSlide: slide} , () => {
                this.sliderWrapperRef.scrollTo({
                            left: left,
                            behavior: 'smooth'
                })
            });
        } else {
            const wrapperWidth = this.sliderWrapperRef.clientWidth;
            this.setState({currentSlide: slide} , () => {
                this.sliderWrapperRef.scrollTo({
                            left: ((slide) * wrapperWidth),
                            behavior: 'smooth'
                })
            });
        }
    }

    setSliderWrapperRef(ref: any) {
        if (ref && !this.sliderWrapperRef) {
            this.sliderWrapperRef = ref;
            this.setState({isSliderWrapperSet: true}, () => {
                if (this.state.isDesktop) {
                    setTimeout(() => {
                        this.sliderWrapperRef.scrollTo({
                            left: this.sliderWrapperRef.clientWidth
                          });
                    }, 500);
                }
            })
        }
    }
 
    setSliderRef(ref: any) {
        if (ref && !this.sliderRef) {
            this.sliderRef = ref;
        }
    }

    getDots() {
        const itemsLength = sliderItemsLength - (!this.state.isDesktop ? 0 : 2);
        const dots = [];
        for(let i = 0; i < itemsLength; i++) {
            dots.push(<img onClick={() => this.slideTo(i + 1)}className="slider-dot" src={i === (this.state.currentSlide - 1) ? '/images/dot_full.svg' : '/images/dot.svg'} alt="o" key={i}/>)
        }
        return dots;
    }

    getTestimonialsCard() {
        const items        = this.getSliderItems();
        sliderItemsLength = items.length;
        const wrapperWidth = this.sliderWrapperRef && this.sliderWrapperRef.clientWidth;
        const sliderStyle  = {gridTemplateColumns: `repeat(${items.length}, ${this.state.isMobile ? '1fr' : wrapperWidth + 'px'})`};
 
        return (<div className='lp-testimonials'>
            <div className="testimonials-header">TESTIMONIALS</div>
            <div className="testimonials">
            <div className="testimonials-background"></div>
            <div className="testimonials-slider">
                {!this.state.isMobile && <div className="slider__arrow">
                    { items.length > 1 &&<img onClick={this.slideLeft} src="/images/arrow-left--white.svg" alt="<"/> }
                </div>}
                <div className="slider__wrapper" ref={ref => this.setSliderWrapperRef(ref)} onScroll={this.onWrapperScroll}>
                    <div className="slider" style={{...sliderStyle}} ref={ref => this.setSliderRef(ref)}>
                        {items}
                    </div>
                </div>
                {!this.state.isMobile && <div className="slider__arrow">
                    { items.length > 1 &&<img onClick={this.slideRight} src="/images/arrow-right--white.svg" alt=">"/>}
                </div>}
            </div>
                <div className="slider-dots">
                    { items.length > 1 && this.getDots()}
                </div>
        </div>
        </div>
        
        );
    }

    render() {
        return this.getTestimonialsCard();
    }
}

export default Testimonials;