import './Login.scss';
import {useTranslation}      from 'react-i18next';
import React, {useEffect, useState}     from 'react';
import {useHistory}          from "react-router-dom";
import NavigationBar         from '../Components/NavigationBar';
import FooterMeta            from '../Footer/FooterMeta';
import {getVXPay}            from '../Helper/VXPayHelper';


function Logout(props: any) {
    const {t}       = useTranslation();
    const history   = useHistory();     
    const guestData = JSON.parse(sessionStorage.getItem('guestData') || '{}');
    const [isLoggedOut, setIsLoggedOut] =  useState(!guestData.id);
   
    function logout(e: any) {
        e.preventDefault(); 
        localStorage.clear();
        sessionStorage.clear();
        getVXPay().logout();
        setIsLoggedOut(true);
    }

    useEffect(()=> {
        setIsLoggedOut(!guestData.id);
    },[guestData.id])

    function cancel(e: any) {
        e.preventDefault(); 
        history.push("/vip/");
    }

    function toLogin() {
        history.push("/login/");
    }

    function toSignUp() {
        history.push("/signup/");
    }

    let content;

    if (isLoggedOut) {
       content =  
       <>
            <div className={"form-group"}>
                <div className='form-group__headline'>{t('SuccessfullyLoggedOut')}</div>
            </div>
            <div>
                <div className={"signup__link"}>{t('AlreadySignedUp')} <span onClick={toLogin} className={"signup__link--blue"}>{t('LoginHere')}</span></div>
                <div className={"signup__link"}>{t('NoAccountYet')} <span onClick={toSignUp} className={"signup__link--blue"}>{t('CreateNow')}</span></div>
            </div>
        </>;

    } else {
        content = (
        <>
            <div className={"form-group"}>
                <div className='form-group__headline'>{t('ReallyLogout')}</div>
            </div>
            <div className={"form-group form-group--space-between"}>
            <form action="#" className={"form form--space-between"}>
                <button className={"form-group__button form-group__button--success"} type="submit" onClick={(e) => logout(e)}>{t('Logout')}</button>
                <button className={"form-group__button form-group__button--cancel"} type="submit" onClick={(e) => cancel(e)}>{t('Cancel')}</button>
            </form>
            </div>
        </>);
    }
    return (
        <div className={"login-container"}>
            <NavigationBar />
            {content}
            <FooterMeta />
        </div>
    );
}

export default Logout;