import {IPageData} from "../App";

function getBackground(data: IPageData) {
	if (window.innerWidth < 600 && data.premiumBackgroundImageUrlMobile) {
		return data.premiumBackgroundImageUrlMobile;
	}

	return data.premiumBackgroundImageUrlDesktop;
}

function onWindowResize(f: EventListenerOrEventListenerObject) {
	window.removeEventListener('resize', f);
	window.addEventListener('resize', f);
}

export {getBackground, onWindowResize};