import {GuestDataType} from "./DataTypes";

const ABO_SERVICE_URL = 'https://abo.vx-services.net';
const VXQL_URL        = 'https://www.visit-x.net/vxql';
const VXQL_TOKEN      = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyVHlwZSI6ImNwIiwicGFydG5lcklkIjo4MTcwLCJyb2xlSWQiOjEwMDAwLCJ1c2VyUG9vbElkIjo4MTcwLCJwZm1JZCI6MTUwNSwicGZtU3VicmVmIjoiIn0.ZtPBVRphovmDONSeilNVTdVO5qdzQ5eCCSA83GgKEyo';

class AboFilter {
	active?: boolean;
	actorId?: number;
	benefitTypes?: Array<'Telegram'>;
	benefitSubTypes?: Array<'CUSTOM'>;
	pfmId?: number;
}

function queryGetFilteredAbos(filter: AboFilter): Promise<Response> {
	filter.pfmId = 1503;

	const query = `
query ($first: Int, $offset: Int, $order: EnumOrder, $filter: AboFilter) {
  getFilteredAbos(first: $first, offset: $offset, order: $order, filter: $filter) {
    items {
      id
      name
      nameTranslation {
        de
        en
      }
      descriptionTranslation {
        de
        en
      }
      description
      picture
      price
      priceDiscounted
      benefitInterval
      paymentInterval
      renew
      active
      trialIntervalCount
      trialPrice
      actorId
      actorName
      pfmId
      creatorId
      createdAt
      benefits {
        id
        type {
          name
          readableIdentifier
        }
        subType {
          name
          readableIdentifier
        }
        singlePayout
        json
      }
    }
    total
  }
}

	`;

	return doQuery(query, {first: null, offset: null, order: null, filter: filter}, ABO_SERVICE_URL);
}

function queryAvailableAbosForGuest(
	guest: GuestDataType,
	actorId: number | null = null,
	withInstance: boolean | null = null,
	benefitType: string | null = null
): Promise<Response> {
	const query = `
query ($actorId: Int, $withInstance: Boolean, $benefitType: EnumBenefitType) {
  guest {
    availableAbos(actorId: $actorId, withInstance: $withInstance, benefitType: $benefitType) {
      abo {
        id
        name
        description
        nameTranslation {
            de
            en
        }
        descriptionTranslation {
            de
            en
        }
        picture
        price
        priceDiscounted
        benefitInterval
        paymentInterval
        renew
        active
        trialIntervalCount
        trialPrice
        actorId
        actorName
        pfmId
        creatorId
        createdAt
        benefits {
          id
          type {
            name
            readableIdentifier
          }
          subType {
            name
            readableIdentifier
          }
          singlePayout
          json
        }
      }
      instances {
        id
        aboId
        guestId
        status
        communipayContractId
        nextRefresh
        start
        end
        lastRefresh
        totalRefreshes
        data {
          currency
          price
          foreignCurrency
          foreignPrice
        }
      }
    }
  }
}
`;

	return doQuery(query, {actorId, withInstance, benefitType}, ABO_SERVICE_URL, guest.vxoneSessionId);
}

function mutateCancelSubscription(guest: GuestDataType, aboId: number): Promise<Response> {
	const query = `
mutation ($aboId: Int!) {
  guest {
    cancelSubscription(aboId: $aboId) {
       success
    }
  }
}
  `;

	return doQuery(query, {aboId}, ABO_SERVICE_URL, guest.vxoneSessionId);
}

function getLegalTexts(filter: any, first: number, offset: number) {
	const args = {filter, first, offset};

	const query = `
	query ($filter: LegalTextsFilter!, $first: Int, $offset: Int) {
		legalTexts(filter: $filter, first: $first, offset: $offset) {
			items {
			  id
			  type 
			  product
			  version
			  language
			  html
			}
		  }
    }	
	`;

	return doQuery(query, args, VXQL_URL, VXQL_TOKEN);
}

/**
 *
 * @param query
 * @param variables
 * @param endpoint
 * @param token
 *
 * @return {Promise}
 */
function doQuery(query: string, variables: object | null, endpoint: string, token?: string): Promise<Response> {
	const queryObject: { query?: string, variables?: object } = {};
	queryObject.query = query;

	if (variables) {
		queryObject.variables = variables;
	}

	const defaultInit = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(queryObject)
	};

	if (token) {
		Object.assign(
			defaultInit.headers,
			{
				'Authorization': 'Bearer ' + token,
			}
		);
	}

	return fetch(endpoint + '?language=DE', defaultInit).then(response => response.json());
}

export {queryGetFilteredAbos, queryAvailableAbosForGuest, mutateCancelSubscription, AboFilter, getLegalTexts};