let _t: any;

class Translations {
    constructor(t: any) {
        _t = t
    }

    static get(key: string, variable: any = null) {
        return _t(key, variable);
    }
    
    static set(t: any) {
        _t = t;
    }
}

export default Translations;