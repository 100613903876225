import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { de, en } from './default'

const options = {
	interpolation: {
		escapeValue: false,
	},

	debug: false,

	resources: {
		de: {
			common: de,
		},
		en: {
			common: en,
		},
	},

	fallbackLng: 'de',

	ns: ['common'],

	defaultNS: 'common',

	detection: {
		caches: []
	},

	react: {
		useSuspense: true,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default'
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init(options);

export default i18n;