import './Login.scss';
import {useTranslation}      from 'react-i18next';
import React, {useEffect,
       useState}             from 'react';
import {useHistory}          from "react-router-dom";
import { message,
         validateInput,
         handleFocusHelper } from '../Helper/Helper';
import {restCall}            from '../Helper/Api';
import {GuestDataType}       from '../Helper/DataTypes';
import FooterMeta            from '../Footer/FooterMeta';
import NavigationBar         from '../Components/NavigationBar';
import PoweredBy             from './PoweredBy';
import {setAccessToken}      from "../Helper/VXPayHelper";
import ReCAPTCHA             from "react-google-recaptcha";
import {getVXPay}            from '../Helper/VXPayHelper';

function Login(props: any) {
    const {t}       = useTranslation();
    const history   = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const [hasVacantPositions, setHasVacantPositions] = useState(false);
    const [hadVacantPositions, setHadVacantPositions] = useState(false);
    const [triedAutoLogin, setTriedAutoLogin] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [showRecaptcha, setShowRecaptcha] = useState(props.captchaData && (props.captchaData.use || (props.captchaData && props.captchaData.useOnFail && loginFailed)));

    if (!hasVacantPositions && (urlParams.get('vacantpositions') === 'false') && (urlParams.get('history') === 'close')) {
        setHasVacantPositions(true);
    }

    if (!hadVacantPositions && (urlParams.get('vacantpositions') === 'false') && (urlParams.get('history') === 'finish')) {
        setHadVacantPositions(true);
    }

    const [recaptchaResponse, setRecaptchaResponse] = useState('');

    function onCaptchaCompleted(token: any) {
        setRecaptchaResponse(token);
    }

    const [text, setText] = useState({
        username: '',
        password: '',
    });

    const [showLabel, setShowLabel] = useState({
        username: false,
        password: false,
    });

    const [showPassword, setShowPassword] = useState(false);

    const [showError, setShowError] = useState({
        username:    {
            show: false,
            text: t('FillOut')
        },
        password: {
            show: false,
            text: t('FillOut'),
        },
    })

    const [showMessage, setShowMessage] = useState({
        isError: false,
        show:    false,
        text:    '',
    })

    const [hasSubmit, setHasSubmit] = useState(false);


    // Get Access Token and Transfer Token
    useEffect(() => {
        if (props.guest) {
            localStorage.clear();
            sessionStorage.clear();
            props.getNewTokens();
            getVXPay().logout();
        }
        if (!props.accessToken) {
            props.prepareAnonymousTokens(props.accessToken);
        }
    },[props]);

	let uhash = urlParams.get('uhash');
	if (uhash && props.accessToken && !triedAutoLogin) {
		const loginData = {
			uhash: uhash,
		}

		callLogin(loginData, undefined, true);
		setTriedAutoLogin(true)
	}

    function handleFocus(evt: any) {
        handleFocusHelper(evt, showLabel, setShowLabel);
    }

    function handleChange(evt: any) {
        setText({ ...text, [evt.target.name]: evt.target.value });
    }

    function toSignUp() {
        history.push("/signup/");
    }

    function togglePassword() {
        setShowPassword(!showPassword);
    }

    function forgetPassword() {
        history.push("/reset/");
    }

    function showBadCredentials() {
        setShowError(
            {
                username:    {
                show: true,
                text: t('BadCredentials')
                },
                password: {
                    show: true,
                    text: t('BadCredentials'),
                }
            });

    }

    function submit(e: any) {
        e.preventDefault();
        if (validateInput(text, showError, setShowError, t('FillOut'), t('EmailWrong'))) { return; }
        const storageContentId: string = sessionStorage.getItem('contentId') || '';
        const contentData:      any    = sessionStorage.getItem('contentData') || {};
        if (props.accessToken) {
            setHasSubmit(true);
            const loginData = {
                login: text.username,
                password: text.password,
                paidContentId: contentData.id,
                uhash: contentData.uhash,
                'g-recaptcha-response': recaptchaResponse,
            }

            callLogin(loginData, storageContentId);
        } else {
            if (validateInput(text, showError, setShowError, t('FillOut'), t('EmailWrong'))) { return; }
            showBadCredentials();
            setHasSubmit(false);
        }
    }

    function callLogin(loginData:object, storageContentId ?: string, autologin:boolean = false){
        restCall('login', props.accessToken, loginData, (data: any) => {
            if (data.success) {

                setAccessToken(props.accessToken);

                if (data.data.isRestricted) {
                    const storage = {
                        uhash: data.data.uhash,
                    };
                    sessionStorage.setItem('vacantPositions', JSON.stringify(storage) );
                    setHasVacantPositions(true);
                    setHasSubmit(false);
                } else {
                    props.setGuest(data.data, (newGuest: GuestDataType) => {
                        if (storageContentId) {
                            history.push(`/buy/${storageContentId}`);
                        } else {
                            if (urlParams.get('destination')) {
                                history.push('/' + urlParams.get('destination') + '/?amount=' + urlParams.get('amount'));
                            } else {
                                history.push(`/vip/`);
                            }
                        }
                    });
                }
            } else if(!data.success && [101, 104, 105].includes(data.error.code)) {
                setHasVacantPositions(true);
                setLoginFailed(true);
                setHasSubmit(false);
            } else if(!autologin) {
                setLoginFailed(true);
                setHasSubmit(false);
                message(t(data.error.message), true, setShowMessage);
                if ([103].includes(data.error.code)) { //captcha-invalid
                    //@TODO: show correct error messages
                    setShowRecaptcha(data.data.captcha.use);
                } else { //108 captcha forced
                    showBadCredentials();
                }
            }
        })
    }

    function toVXPay(uhash: string, actorId: number) {
        const vxpay = getVXPay()
        vxpay.hooks.then((response: any) => {
            response.onSuccess(() => {
                setHadVacantPositions(false);
                setHasVacantPositions(false);
                //callLogin({uhash: res.user._uhash});
            })
        })
       getVXPay().openLogin({uhash});
    }

    function toLogin() {
        setHadVacantPositions(false);
        history.push('/login/');
    }

    function getStorage() {
        return JSON.parse(sessionStorage.getItem('vacantPositions') || '{}');
    }

    function getContent() {
        const btnDisabled = showRecaptcha && !recaptchaResponse;

        if (hadVacantPositions) {
            sessionStorage.removeItem('vacantPositions');
            return (
                <div className={"reset-info"}>
                    <span className={"content__text"}><i className={"icon icon--mr10 icon--sm icon--check"}></i> {t('NoVacantPositions')}</span>
                    <div className={"content__text-wrapper content__text-wrapper--show"}>
                        <span onClick={toLogin} className={"content__subtext mt-10 pointer"}>{t('LoginHere')}</span>
                    </div>
                </div>
            );

        } else if (hasVacantPositions) {
            const storage = getStorage();
            const uhash   = storage.uhash;
            return (
                <div className={"reset-info"}>
                    <span className={"content__text"}><i className={"icon icon--mr10 icon--sm icon--cancel"}></i> {uhash ? t('VacantPositions') : t('AccountLocked')}</span>
                    <div className={"content__text-wrapper content__text-wrapper--show"}>
                        {uhash && <span onClick={()=>toVXPay(uhash, props.actorId)} className={"content__subtext mt-10 pointer"}>{t('PayVacantPositions')}</span>}
                        {!uhash && <div onClick={()=>toVXPay(uhash, props.actorId)} className={"content__subtext content__subtext--vacant mt-10"}>{t('PayVacantPositionsSupport')} <span>{t('PayVacantPositionsSupportClick')}</span></div>}
                    </div>
                </div>
            );
        } else {
            return(
                <form action="#" className={"form"}>
                    <div className="form-group__headline">
                    Telegram VIP
                    </div>
                <div className={"form-group"}>
                    <input className={"form-group__input"} type="email" onFocus={handleFocus} onBlur={handleFocus} onChange={handleChange} name="username" required />
                    <label className={"form-group__label " + (showLabel.username ? "form-group__label--move-top" : "")}>{t('Username')}</label>
                    <label className={"form-group__label-error form-group__label-error--top " + (showError.username.show ? "form-group__label-error--show" : "")}>{showError.username.text}</label>
                    <span className={"form-group__info"}><i className={"icon icon--xs icon--info"}></i>{t('LoginInfo')}</span>
                </div>
                <div className={"form-group"}>
                    <input className={"form-group__input"} type={showPassword ? "text" : "password"} onFocus={handleFocus} onBlur={handleFocus} onChange={handleChange} name="password" autoComplete="off" required/>
                    <label className={"form-group__label " + (showLabel.password ? "form-group__label--move-top" : "")}>{t('Password')}</label>
                    <label className={"form-group__label-error " + (showError.password.show ? "form-group__label-error--show" : "")}>{showError.password.text}</label>
                    <div onClick={togglePassword} className={"form-group__password-toggle"}><i className={"icon " +  (showPassword ? "icon--visibility-off" : "icon--visibility")}></i></div>
                </div>
                {showRecaptcha && <div className={"form-group"}><div className="form-group__recaptcha"><ReCAPTCHA sitekey={props.captchaData.key} onChange={onCaptchaCompleted}/></div></div>}

                {!hasSubmit && <button className={"form-group__button " + (btnDisabled ? 'form-group__button--disabled' : '')} disabled={btnDisabled} type="submit" onClick={(e) => submit(e)}><i className={"icon icon--xs icon--user"}></i> {t('Login')}</button>}
                {hasSubmit && <button className={"form-group__button "} type="submit" onClick={(e) => e.preventDefault()}><span className="spinner"></span></button>}
                <div onClick={forgetPassword} className={"form-group__link"}>{t('ForgetPassword')}</div>
            </form>
            );
        }

    }

    return (
        <div className={"login-container"}>
            <div className={"login-container__message " + (showMessage.show ? "login-container__message--show " : "") + (showMessage.isError ? "login-container__message--error " : "")}>{showMessage.text}</div>
            <div>
                <NavigationBar showMenu={false} />
                {getContent()}

            </div>
            <div>
                <div className={"signup__link"}>{t('NoAccountYet')} <span onClick={toSignUp} className={"signup__link--blue"}>{t('CreateNow')}</span></div>
                {PoweredBy}
                <FooterMeta />
            </div>
        </div>
    );
}

export default Login;