import Cookies from 'js-cookie';

function setAnalyticsCookies(useCookie) {
    if (useCookie) {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KHLG5NB');
    } else {
        const splittedUrl = window.location.hostname.split('.');
        let hostname = window.location.hostname;
        if (splittedUrl.length > 2) {
            hostname = splittedUrl.slice(1).join('.');
        }
        Cookies.remove('_gat_UA-8202511-74', { path: '/', domain: hostname} );
		Cookies.remove('_ga', { path: '/', domain: hostname} );
		Cookies.remove('_gid', { path: '/', domain: hostname} );
    }
}

export {
    setAnalyticsCookies,
}