import i18n from './i18n/index';

interface TextInterface {
    username: "",
    password: ""
}

interface ErrorInterface {
    username: {show: false, text: ""},
    password: {show: false, text: ""},
}

function handleFocusHelper(evt: any, showLabel: any, setShowLabel: any) {
    const name: keyof TextInterface  = evt.target.name;
    const value: string = evt.target.value;
    
    if (value !== "") {
        setShowLabel({ ...showLabel, [evt.target.name]: true });
    } else if (showLabel[name]) {
        setShowLabel({ ...showLabel, [evt.target.name]: false });
    } else {
        setShowLabel({ ...showLabel, [evt.target.name]: true });
    }
}

function validateInput(text: any, showError: any, setShowError: any, defaultText: string, emailWrong: string) {
    let txt: keyof any;
    let isError    = false;
    const tmpError = showError;
    for (txt in text) {
        tmpError[txt].text = defaultText;
        if (text[txt]) {
            tmpError[txt].show = false;
        } else {
            tmpError[txt].show = true;
            isError = true;
        }
        if ((text[txt] && txt === 'email') && (text[txt].indexOf('@') < 0 || text[txt].indexOf('.') < 0)) {
            isError = true;
            tmpError[txt].text = emailWrong;
            tmpError[txt].show = true;
        }
    }
    setShowError({...showError});
    return isError;
}


function message(text: string, isError: boolean, setShowMessage: any) {
    setShowMessage({text, isError, show: true});
    setTimeout(() => {
        setShowMessage({text, isError, show: false});
    }, 3000); 
}

function isGerman() {
    return getLanguage() === 'de';
}

function getLanguage() {
    return (i18n && i18n.language) ? i18n.language : (navigator.language.split('-')[0] || 'de');
}

function getPrice(price: number | undefined, currency: string) {
    if (!price) {
        return;
    }
    
    let newPrice = price.toString();

    if(newPrice.includes('.') && newPrice.split('.')[1].length === 1) {
        newPrice = newPrice + '0';
    }

    if (!isGerman() || currency !== '€') {
        return newPrice;
    } else {
        return newPrice.replace('.', ',');
    }
}

function getCurrencySign(currency: string) {
    switch(currency) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        default:
            return '€';
    }
}

export {
    handleFocusHelper,
    validateInput,
    message,
    getPrice,
    isGerman,
    getLanguage,
    getCurrencySign
}