import './tip.scss';
import React      from 'react'
import Translations from '../Helper/Translation';


const PERIODS  = {
    WEEK: 'week',
    MONTH: 'month',
    OVERALL: 'overall',
}

interface IProps {
    modelName:   string,
    rankingList: any,
}

interface IState {
    period: string;
  }

export default class Ranking extends React.Component<IProps, IState> {    
    constructor(props: IProps) {
        super(props);

        this.state = {
            period: PERIODS.WEEK,  
        }
    }

    getListSelector() {
        const setPeriod = (period: string) => {
            this.setState({period});
        }

        return (
            <div className="ranking__selector">
                <div className={"ranking__selector__item" + (this.state.period === PERIODS.WEEK ? " ranking__selector__item--active" : "")} onClick={() => setPeriod(PERIODS.WEEK)}>{Translations.get('week')}</div>
                <div className={"ranking__selector__item" + (this.state.period === PERIODS.MONTH ? " ranking__selector__item--active" : "")} onClick={() => setPeriod(PERIODS.MONTH)}>{Translations.get('month')}</div>
                <div className={"ranking__selector__item" + (this.state.period === PERIODS.OVERALL ? " ranking__selector__item--active" : "")}onClick={() => setPeriod(PERIODS.OVERALL)}>{Translations.get('overall')}</div>
            </div>
        );
    }

    getList() {
        const formatName = (name: string) => {
            let formattedName = '';
            const names = name.split(' ');
            names.map((item) => formattedName += item.substr(0,1));
            return formattedName;
        }

        const list = this.props.rankingList.map((item: any, index: number) => {
            return(
                <div className="ranking__list-item" key={index}>
                <div className="ranking__list-item__rank">#{item.rank}</div>
                <div className="ranking__list-item__image">{formatName(item.name)}</div>
                <div className="ranking__list-item__name-container">
                    <div className="ranking__list-item__name">{item.name}</div>
                    <div className="ranking__list-item__badge">
                        <div className="ranking__update-badge ranking__update-badge--list"><span role="img" aria-label="money">💸</span> {item.rankBadge} </div>
                    </div>
                </div>
            </div>
            );
        })
        
        return (
            <div className="ranking__list ranking__list--margin">
                {list}
            </div>
        );

    }

    getRankingList() {
        return (
            <div className="ranking__list">
               {/*this.getListSelector()*/}
               {this.getList()}
            </div>
        );
    
    }


    render() {
        let content = (
        <div className={"ranking"}>
            <span className="ranking__icon" role="img" aria-label="ranking">🏆 </span>
            <div className="ranking__headline">Power Fan Ranking</div>
            <div className="ranking__info">{Translations.get('tip-ranking-tipped-text-1')} <br /> {Translations.get('tip-ranking-tipped-text-2', {modelname: this.props.modelName})}</div>
            {this.getRankingList()}
        </div>);

        return content
    }
}

