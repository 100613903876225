import './Login.scss';
import {useTranslation}    from 'react-i18next';
import React, {useState, useEffect}   from 'react';
import {useHistory}        from "react-router-dom";
import { validateInput, 
        handleFocusHelper }  from '../Helper/Helper';
import { restCall }          from '../Helper/Api';
import FooterMeta            from '../Footer/FooterMeta';
import NavigationBar         from '../Components/NavigationBar';
import {handleVerifyEmail}   from './EmailConfirmation';

function Resetemail(props: any) {
    const {t}         = useTranslation();
    const history     = useHistory();  
    const queryString = window.location.search;
    const urlParams   = new URLSearchParams(queryString);  
    const paramCode   = urlParams.get('code');

    const [resetPassword, setResetPassword]           = useState(false);
    const [passwordHasResetet, setPasswordHasResetet] = useState(false);
    const [passwordHasChanged, setPasswordHasChanged] = useState(false);
    const [inputRequest, setInputRequest]             = useState({
        username: '',
        email:    '',
    });

    const [inputChange, setInputChange] = useState({
        password:    '',
        passwordRpt: '',
    })

    const [showRequestLabel, setShowRequestLabel] = useState({
        username: false,
        email:    false,
    });

    const [showChangeLabel, setShowChangeLabel] = useState({
        password:    false,
        passwordRpt: false,
    });
   
    const [showRequestError, setShowRequestError] = useState({
        username:    {
            show: false,
            text: t('FillOut')
        },
        email: {
            show: false, 
            text: t('FillOut'),
        },
    });

    const [showChangeError, setShowChangeError] = useState({
        password:    {
            show: false,
            text: t('FillOut')
        },
        passwordRpt: {
            show: false, 
            text: t('FillOut'),
        },
    });

    const [hasSubmit, setHasSubmit] = useState(false);

  
    useEffect(() => {
        if (props.accessToken) {
            handleVerifyEmail(props.accessToken, () => true);
        }
    }, [props.accessToken]);

    function handleRequestFocus(evt: any) {
        handleFocusHelper(evt, showRequestLabel, setShowRequestLabel);
    }

    function handleChangeFocus(evt: any) {
        handleFocusHelper(evt, showChangeLabel, setShowChangeLabel);
    }

    function handleInputRequest(evt: any) {
        setInputRequest({ ...inputRequest, [evt.target.name]: evt.target.value });
    }

    function handleInputChange(evt: any) {
        setInputChange({ ...inputChange, [evt.target.name]: evt.target.value });
    }

    function setShowBadCredentialsRequest() {
        setShowRequestError(        
            {
                username:    {
                show: true,
                text: t('BadCredentials')
                },
                email: {
                    show: true, 
                    text: t('BadCredentials'),
                }
            });
    }

    function setShowBadCredentialsChange() {
        setShowChangeError(        
            {
                password:    {
                show: true,
                text: t('BadCredentials')
                },
                passwordRpt: {
                    show: true, 
                    text: t('BadCredentials'),
                }
            });
    }

    function submitRequest(e: any) {
        e.preventDefault();
        if (validateInput(inputRequest, showRequestError, setShowRequestError, t('FillOut'), t('EmailWrong'))) { return; }
        if (props.accessToken) {
            setHasSubmit(true);
            restCall('lost-password',props.accessToken, {screenname: inputRequest.username, email: inputRequest.email}, (data: any) => {
                if (data.success) {
                    setPasswordHasResetet(true);
                } else {
                    setShowBadCredentialsRequest();
                    setHasSubmit(false);
                }
            });
        } else {
            setHasSubmit(false);
            setShowBadCredentialsRequest();
        }
    }

    function submitChange(e: any) {
        e.preventDefault();
        if (validateInput(inputChange, showChangeError, setShowChangeError, t('FillOut'), t('PasswordWrong'))) { return; }
        if (inputChange.password !== inputChange.passwordRpt) {
            setShowChangeError({password: {show: true, text: t('PasswordRepeatWrong')}, passwordRpt: {show: true, text: t('PasswordRepeatWrong')}});
        } else {
            setShowChangeError({password: {show: false, text: t('PasswordRepeatWrong')}, passwordRpt: {show: false, text: t('PasswordRepeatWrong')}});
            const data = {
                key: paramCode,
                screenname: urlParams.get('tpLoginPwdLost'),
                u: urlParams.get('u')
            }
            restCall('lost-password/validate',props.accessToken, data, (respValidate: any) => {
                if (respValidate.success) {
                    restCall('lost-password/change',props.accessToken, {password: inputChange.password}, (respChange: any) => {
                        if (respChange.success) {
                            setPasswordHasChanged(true);
                        } else {
                            setShowBadCredentialsChange();
                        }
                    });
                } else {
                    setShowBadCredentialsChange();
                }
            })
        }
    }

    if (!resetPassword) {
        if (typeof paramCode === 'string' && paramCode !== '') {
            setInputRequest({ username: urlParams.get('tpLoginPwdLost') || '', email: '' })
            setResetPassword(true);
        }
    }

    // get content depending on current state( passwordHasChanged || passwordHasResetet || resetPassword || landing page )
    function getContent() {
        if (passwordHasChanged) {
            //setTimeout(() => history.push('/login/'), 3000);
            return (
                <div className={"reset-info"}>
                    <span className={"content__text"}><i className={"icon icon--mr10 icon--sm icon--check"}></i> {t('PasswordHasReset')}</span>
                    <div className={"content__text-wrapper content__text-wrapper--show"}>
                        <span className={"content__subtext mt-10"}>{t('BackToTelegram')}</span>
                    </div>
                </div>
            );
        }

        if (passwordHasResetet) {
            return (
                <div className={"reset-info"}>
                    <span className={"content__text"}><i className={"icon icon--mr10 icon--sm icon--check"}></i> {t('PasswordResetet')}</span>
                    <div className={"content__text-wrapper content__text-wrapper--show"}>
                        <span className={"content__subtext mt-10"}>{t('PasswordWillReset')}</span>
                    </div>
                </div>
            );
        }    

        if (resetPassword) {
            return (
                [
                    <span className={"content__text mt-30"} key="1">{t('SetNewPassword')}</span>,
                    <form action="#" key="2">
                        <div className={"form-group"}>
                            <input className={"form-group__input"} type={"text"} name="username" value={inputRequest.username} disabled/>
                            <label className={"form-group__label form-group__label--move-top"}>{t('Username')}</label>
                        </div>
                    </form>,
                    <form action="#" key="3">
                        <div className={"form-group"}>
                            <input className={"form-group__input"} type="password" onFocus={handleChangeFocus} onBlur={handleChangeFocus} onChange={handleInputChange} name="password" required />
                            <label className={"form-group__label " + (showChangeLabel.password ? "form-group__label--move-top" : "")}>{t('Password')}</label>
                            <label className={"form-group__label-error " + (showRequestError.username.show ? "form-group__label-error--show" : "")}>{showRequestError.username.text}</label>
                        </div>
                        <div className={"form-group"}>
                            <input className={"form-group__input"} type={"password"} onFocus={handleChangeFocus} onBlur={handleChangeFocus} onChange={handleInputChange} name="passwordRpt" required/>
                            <label className={"form-group__label " + (showChangeLabel.passwordRpt ? "form-group__label--move-top" : "")}>{t('PasswordRepeat')}</label>
                            <label className={"form-group__label-error " + (showRequestError.email.show ? "form-group__label-error--show" : "")}>{showRequestError.email.text}</label>
                        </div>
                        {!hasSubmit && <button className={"form-group__button"} type="submit" onClick={(e) => submitChange(e)} key="3"><i className={"icon icon--xs icon--user"}></i> {t('ChangePassword')}</button>}
                        {hasSubmit && <button className={"form-group__button"} type="submit" onClick={(e) => e.preventDefault()}><span className="spinner"></span></button>}

                    </form>
                ]
            );
        } else {
            return (
                [
                <span className={"content__text mt-30"} key="1">{t('ForgetPassword')}</span>,
                <div className={"content__text-wrapper content__text-wrapper--show"} key="2">
                    <span className={"content__subtext mt-10"}>{t('ForgetPasswordSubline')}</span>
                </div>,
                <form action="#" key="3">
                    <div className={"form-group"} >
                        <label className={"form-group__label " + (showRequestLabel.username ? "form-group__label--move-top" : "")}>{t('Username')}</label>
                        <label className={"form-group__label-error " + (showRequestError.username.show ? "form-group__label-error--show" : "")}>{showRequestError.username.text}</label>
                        <input className={"form-group__input"} type="text" onFocus={handleRequestFocus} onBlur={handleRequestFocus} onChange={handleInputRequest} name="username" required />
                    </div>
                    <div className={"form-group"}>
                        <label className={"form-group__label " + (showRequestLabel.email ? "form-group__label--move-top" : "")}>{t('Email')}</label>
                        <label className={"form-group__label-error " + (showRequestError.email.show ? "form-group__label-error--show" : "")}>{showRequestError.email.text}</label>
                        <input className={"form-group__input"} type={"email"} onFocus={handleRequestFocus} onBlur={handleRequestFocus} onChange={handleInputRequest} name="email" required/>
                    </div>
                    <button className={"form-group__button"} type="submit" onClick={(e) => submitRequest(e)}><i className={"icon icon--xs icon--user"}></i> {t('ResetPassword')}</button>
                </form>
                ]
            );
        }
    }

    return  (
        <div className={"login-container"}>
            <div>
                <NavigationBar showMenu={false} showBack={history.length > 1}/>
                <div className="form-group__headline">Telegram VIP</div>
                {getContent()}
            </div>
            <div>
                <FooterMeta />
            </div>
        </div>
    );
}

export default Resetemail;