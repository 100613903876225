import { useTranslation} from 'react-i18next';
import React             from "react";
import { Link }          from "react-router-dom";
import RouterHelper      from '../Helper/RouterHelper';
import { isGerman }      from '../Helper/Helper';



function FooterMeta({showConditions = true}) {
    const {t}   = useTranslation();
    let imprintLink               = RouterHelper.Routes.DE.IMPRINT;
    let conditionsLink            = RouterHelper.Routes.DE.CONDITIONS;
    let dataProtectionLink        = RouterHelper.Routes.DE.DATA_PROTECTION;
    if (!isGerman()) {
        imprintLink               = RouterHelper.Routes.EN.IMPRINT;
        conditionsLink            = RouterHelper.Routes.EN.CONDITIONS;
        dataProtectionLink        = RouterHelper.Routes.EN.DATA_PROTECTION;
    } 
    return (
        <div className={"footer__meta"}>
            <Link className={"footer__meta-item"} to={imprintLink} >{t('ImprintLink')}</Link>
            {showConditions && <Link className={"footer__meta-item"} to={conditionsLink} >{t('AbgLink')}</Link>}
            <Link className={"footer__meta-item"} to={dataProtectionLink} >{t('DataProtectionLink')}</Link>
        </div>
    )
}

export default FooterMeta;